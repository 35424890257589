/**
 * File generated by js-routes 2.2.2
 * Based on Rails 6.1.7.6 routes of WebServer::Application
 */
const __jsr = ((that) => {
    const hasProp = (value, key) => Object.prototype.hasOwnProperty.call(value, key);
    let NodeTypes;
    (function (NodeTypes) {
        NodeTypes[NodeTypes["GROUP"] = 1] = "GROUP";
        NodeTypes[NodeTypes["CAT"] = 2] = "CAT";
        NodeTypes[NodeTypes["SYMBOL"] = 3] = "SYMBOL";
        NodeTypes[NodeTypes["OR"] = 4] = "OR";
        NodeTypes[NodeTypes["STAR"] = 5] = "STAR";
        NodeTypes[NodeTypes["LITERAL"] = 6] = "LITERAL";
        NodeTypes[NodeTypes["SLASH"] = 7] = "SLASH";
        NodeTypes[NodeTypes["DOT"] = 8] = "DOT";
    })(NodeTypes || (NodeTypes = {}));
    const Root = that;
    const isBroswer = typeof window !== "undefined";
    const ModuleReferences = {
        CJS: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                module.exports = routes;
            },
            isSupported() {
                return typeof module === "object";
            },
        },
        AMD: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                define([], function () {
                    return routes;
                });
            },
            isSupported() {
                return typeof define === "function" && !!define.amd;
            },
        },
        UMD: {
            define(routes) {
                if (ModuleReferences.AMD.isSupported()) {
                    ModuleReferences.AMD.define(routes);
                }
                else {
                    if (ModuleReferences.CJS.isSupported()) {
                        try {
                            ModuleReferences.CJS.define(routes);
                        }
                        catch (error) {
                            if (error.name !== "TypeError")
                                throw error;
                        }
                    }
                }
            },
            isSupported() {
                return (ModuleReferences.AMD.isSupported() ||
                    ModuleReferences.CJS.isSupported());
            },
        },
        ESM: {
            define() {
                // Module can only be defined using ruby code generation
            },
            isSupported() {
                // Its impossible to check if "export" keyword is supported
                return true;
            },
        },
        NIL: {
            define(routes) {
                Utils.namespace(Root, null, routes);
            },
            isSupported() {
                return !null || !!Root;
            },
        },
        DTS: {
            // Acts the same as ESM
            define(routes) {
                ModuleReferences.ESM.define(routes);
            },
            isSupported() {
                return ModuleReferences.ESM.isSupported();
            },
        },
    };
    class ParametersMissing extends Error {
        constructor(...keys) {
            super(`Route missing required keys: ${keys.join(", ")}`);
            this.keys = keys;
            Object.setPrototypeOf(this, Object.getPrototypeOf(this));
            this.name = ParametersMissing.name;
        }
    }
    const UriEncoderSegmentRegex = /[^a-zA-Z0-9\-._~!$&'()*+,;=:@]/g;
    const ReservedOptions = [
        "anchor",
        "trailing_slash",
        "subdomain",
        "host",
        "port",
        "protocol",
    ];
    class UtilsClass {
        constructor() {
            this.configuration = {
                prefix: "",
                default_url_options: {"host":"erural.net","port":null,"subdomain":"www"},
                special_options_key: "_options",
                serializer: null || this.default_serializer.bind(this),
            };
        }
        default_serializer(value, prefix) {
            if (this.is_nullable(value)) {
                return "";
            }
            if (!prefix && !this.is_object(value)) {
                throw new Error("Url parameters should be a javascript hash");
            }
            prefix = prefix || "";
            const result = [];
            if (this.is_array(value)) {
                for (const element of value) {
                    result.push(this.default_serializer(element, prefix + "[]"));
                }
            }
            else if (this.is_object(value)) {
                for (let key in value) {
                    if (!hasProp(value, key))
                        continue;
                    let prop = value[key];
                    if (this.is_nullable(prop) && prefix) {
                        prop = "";
                    }
                    if (this.is_not_nullable(prop)) {
                        if (prefix) {
                            key = prefix + "[" + key + "]";
                        }
                        result.push(this.default_serializer(prop, key));
                    }
                }
            }
            else {
                if (this.is_not_nullable(value)) {
                    result.push(encodeURIComponent(prefix) + "=" + encodeURIComponent("" + value));
                }
            }
            return result.join("&");
        }
        serialize(object) {
            return this.configuration.serializer(object);
        }
        extract_options(number_of_params, args) {
            const last_el = args[args.length - 1];
            if ((args.length > number_of_params && last_el === 0) ||
                (this.is_object(last_el) &&
                    !this.looks_like_serialized_model(last_el))) {
                if (this.is_object(last_el)) {
                    delete last_el[this.configuration.special_options_key];
                }
                return {
                    args: args.slice(0, args.length - 1),
                    options: last_el,
                };
            }
            else {
                return { args, options: {} };
            }
        }
        looks_like_serialized_model(object) {
            return (this.is_object(object) &&
                !(this.configuration.special_options_key in object) &&
                ("id" in object || "to_param" in object || "toParam" in object));
        }
        path_identifier(object) {
            const result = this.unwrap_path_identifier(object);
            return this.is_nullable(result) || result === false ? "" : "" + result;
        }
        unwrap_path_identifier(object) {
            let result = object;
            if (!this.is_object(object)) {
                return object;
            }
            if ("to_param" in object) {
                result = object.to_param;
            }
            else if ("toParam" in object) {
                result = object.toParam;
            }
            else if ("id" in object) {
                result = object.id;
            }
            else {
                result = object;
            }
            return this.is_callable(result) ? result.call(object) : result;
        }
        partition_parameters(parts, required_params, default_options, call_arguments) {
            // eslint-disable-next-line prefer-const
            let { args, options } = this.extract_options(parts.length, call_arguments);
            if (args.length > parts.length) {
                throw new Error("Too many parameters provided for path");
            }
            let use_all_parts = args.length > required_params.length;
            const parts_options = {
                ...this.configuration.default_url_options,
            };
            for (const key in options) {
                const value = options[key];
                if (!hasProp(options, key))
                    continue;
                use_all_parts = true;
                if (parts.includes(key)) {
                    parts_options[key] = value;
                }
            }
            options = {
                ...this.configuration.default_url_options,
                ...default_options,
                ...options,
            };
            const keyword_parameters = {};
            const query_parameters = {};
            for (const key in options) {
                if (!hasProp(options, key))
                    continue;
                const value = options[key];
                if (this.is_reserved_option(key)) {
                    keyword_parameters[key] = value;
                }
                else {
                    if (!this.is_nullable(value) &&
                        (value !== default_options[key] || required_params.includes(key))) {
                        query_parameters[key] = value;
                    }
                }
            }
            const route_parts = use_all_parts ? parts : required_params;
            let i = 0;
            for (const part of route_parts) {
                if (i < args.length) {
                    const value = args[i];
                    if (!hasProp(parts_options, part)) {
                        query_parameters[part] = value;
                        ++i;
                    }
                }
            }
            return { keyword_parameters, query_parameters };
        }
        build_route(parts, required_params, default_options, route, absolute, args) {
            const { keyword_parameters, query_parameters, } = this.partition_parameters(parts, required_params, default_options, args);
            const missing_params = required_params.filter((param) => !hasProp(query_parameters, param) ||
                this.is_nullable(query_parameters[param]));
            if (missing_params.length) {
                throw new ParametersMissing(...missing_params);
            }
            let result = this.get_prefix() + this.visit(route, query_parameters);
            if (keyword_parameters.trailing_slash) {
                result = result.replace(/(.*?)[/]?$/, "$1/");
            }
            const url_params = this.serialize(query_parameters);
            if (url_params.length) {
                result += "?" + url_params;
            }
            result += keyword_parameters.anchor
                ? "#" + keyword_parameters.anchor
                : "";
            if (absolute) {
                result = this.route_url(keyword_parameters) + result;
            }
            return result;
        }
        visit(route, parameters, optional = false) {
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return this.visit(route[1], parameters, true);
                case NodeTypes.CAT:
                    return this.visit_cat(route, parameters, optional);
                case NodeTypes.SYMBOL:
                    return this.visit_symbol(route, parameters, optional);
                case NodeTypes.STAR:
                    return this.visit_globbing(route[1], parameters, true);
                case NodeTypes.LITERAL:
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        is_not_nullable(object) {
            return !this.is_nullable(object);
        }
        is_nullable(object) {
            return object === undefined || object === null;
        }
        visit_cat(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, left, right], parameters, optional) {
            const left_part = this.visit(left, parameters, optional);
            let right_part = this.visit(right, parameters, optional);
            if (optional &&
                ((this.is_optional_node(left[0]) && !left_part) ||
                    (this.is_optional_node(right[0]) && !right_part))) {
                return "";
            }
            // if left_part ends on '/' and right_part starts on '/'
            if (left_part[left_part.length - 1] === "/" && right_part[0] === "/") {
                // strip slash from right_part
                // to prevent double slash
                right_part = right_part.substring(1);
            }
            return left_part + right_part;
        }
        visit_symbol(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, key], parameters, optional) {
            const value = this.path_identifier(parameters[key]);
            delete parameters[key];
            if (value.length) {
                return this.encode_segment(value);
            }
            if (optional) {
                return "";
            }
            else {
                throw new ParametersMissing(key);
            }
        }
        encode_segment(segment) {
            return segment.replace(UriEncoderSegmentRegex, (str) => encodeURIComponent(str));
        }
        is_optional_node(node) {
            return [NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT].includes(node);
        }
        build_path_spec(route, wildcard = false) {
            let key;
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return "(" + this.build_path_spec(route[1]) + ")";
                case NodeTypes.CAT:
                    return (this.build_path_spec(route[1]) + this.build_path_spec(route[2]));
                case NodeTypes.STAR:
                    return this.build_path_spec(route[1], true);
                case NodeTypes.SYMBOL:
                    key = route[1];
                    if (wildcard) {
                        return (key.startsWith("*") ? "" : "*") + key;
                    }
                    else {
                        return ":" + key;
                    }
                    break;
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                case NodeTypes.LITERAL:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        visit_globbing(route, parameters, optional) {
            const key = route[1];
            let value = parameters[key];
            delete parameters[key];
            if (this.is_nullable(value)) {
                return this.visit(route, parameters, optional);
            }
            if (this.is_array(value)) {
                value = value.join("/");
            }
            const result = this.path_identifier(value);
            return false
                ? result
                : encodeURI(result);
        }
        get_prefix() {
            const prefix = this.configuration.prefix;
            return prefix.match("/$")
                ? prefix.substring(0, prefix.length - 1)
                : prefix;
        }
        route(parts_table, route_spec, absolute = false) {
            const required_params = [];
            const parts = [];
            const default_options = {};
            for (const [part, { r: required, d: value }] of Object.entries(parts_table)) {
                parts.push(part);
                if (required) {
                    required_params.push(part);
                }
                if (this.is_not_nullable(value)) {
                    default_options[part] = value;
                }
            }
            const result = (...args) => {
                return this.build_route(parts, required_params, default_options, route_spec, absolute, args);
            };
            result.requiredParams = () => required_params;
            result.toString = () => {
                return this.build_path_spec(route_spec);
            };
            return result;
        }
        route_url(route_defaults) {
            const hostname = route_defaults.host || this.current_host();
            if (!hostname) {
                return "";
            }
            const subdomain = route_defaults.subdomain
                ? route_defaults.subdomain + "."
                : "";
            const protocol = route_defaults.protocol || this.current_protocol();
            let port = route_defaults.port ||
                (!route_defaults.host ? this.current_port() : undefined);
            port = port ? ":" + port : "";
            return protocol + "://" + subdomain + hostname + port;
        }
        current_host() {
            var _a;
            return (isBroswer && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.hostname)) || "";
        }
        current_protocol() {
            var _a, _b;
            return ((isBroswer && ((_b = (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.protocol) === null || _b === void 0 ? void 0 : _b.replace(/:$/, ""))) || "http");
        }
        current_port() {
            var _a;
            return (isBroswer && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.port)) || "";
        }
        is_object(value) {
            return (typeof value === "object" &&
                Object.prototype.toString.call(value) === "[object Object]");
        }
        is_array(object) {
            return object instanceof Array;
        }
        is_callable(object) {
            return typeof object === "function" && !!object.call;
        }
        is_reserved_option(key) {
            return ReservedOptions.includes(key);
        }
        namespace(object, namespace, routes) {
            const parts = (namespace === null || namespace === void 0 ? void 0 : namespace.split(".")) || [];
            if (parts.length === 0) {
                return;
            }
            for (let index = 0; index < parts.length; index++) {
                const part = parts[index];
                if (index < parts.length - 1) {
                    object = object[part] || (object[part] = {});
                }
                else {
                    object[part] = routes;
                }
            }
        }
        configure(new_config) {
            this.configuration = { ...this.configuration, ...new_config };
            return this.configuration;
        }
        config() {
            return { ...this.configuration };
        }
        is_module_supported(name) {
            return ModuleReferences[name].isSupported();
        }
        ensure_module_supported(name) {
            if (!this.is_module_supported(name)) {
                throw new Error(`${name} is not supported by runtime`);
            }
        }
        define_module(name, module) {
            this.ensure_module_supported(name);
            ModuleReferences[name].define(module);
        }
    }
    const Utils = new UtilsClass();
    // We want this helper name to be short
    const __jsr = {
        r(parts_table, route_spec, absolute) {
            return Utils.route(parts_table, route_spec, absolute);
        },
    };
    const result = {
        ...__jsr,
        configure: (config) => {
            return Utils.configure(config);
        },
        config: () => {
            return Utils.config();
        },
        serialize: (object) => {
            return Utils.serialize(object);
        },
        ...{},
    };
    Utils.define_module("ESM", result);
    return result;
})(this);
export const configure = __jsr.configure;

export const config = __jsr.config;

export const serialize = __jsr.serialize;

/**
 * Generates rails route to
 * /editar_senha(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const acc_edit_password_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"editar_senha"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /editar_senha(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const acc_edit_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"editar_senha"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /update_password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const acc_update_password_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"update_password"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /update_password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const acc_update_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"update_password"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /pa/gerenciamento-contas/adicionar-role-admin(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_accounts_add_admin_role_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"gerenciamento-contas"],[2,[7,"/"],[2,[6,"adicionar-role-admin"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/gerenciamento-contas/adicionar-role-admin(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_accounts_add_admin_role_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"gerenciamento-contas"],[2,[7,"/"],[2,[6,"adicionar-role-admin"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/gerenciamento-contas(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_accounts_index_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"gerenciamento-contas"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /pa/gerenciamento-contas(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_accounts_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"gerenciamento-contas"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /pa/gerenciamento-contas/equipe(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_accounts_list_admin_team_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"gerenciamento-contas"],[2,[7,"/"],[2,[6,"equipe"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/gerenciamento-contas/equipe(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_accounts_list_admin_team_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"gerenciamento-contas"],[2,[7,"/"],[2,[6,"equipe"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/gerenciamento-contas/:id/remover-role-admin(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_accounts_remove_admin_role_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"gerenciamento-contas"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"remover-role-admin"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/gerenciamento-contas/:id/remover-role-admin(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_accounts_remove_admin_role_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"gerenciamento-contas"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"remover-role-admin"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/gerenciamento-contas/responsaveis(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_accounts_responsibles_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"gerenciamento-contas"],[2,[7,"/"],[2,[6,"responsaveis"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/gerenciamento-contas/responsaveis(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_accounts_responsibles_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"gerenciamento-contas"],[2,[7,"/"],[2,[6,"responsaveis"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/gerenciamento-contas/:id/alterar-pod(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_accounts_update_pod_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"gerenciamento-contas"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"alterar-pod"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/gerenciamento-contas/:id/alterar-pod(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_accounts_update_pod_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"gerenciamento-contas"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"alterar-pod"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/gerenciamento-contas/:id/alterar-role(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_accounts_update_roles_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"gerenciamento-contas"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"alterar-role"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/gerenciamento-contas/:id/alterar-role(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_accounts_update_roles_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"gerenciamento-contas"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"alterar-role"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/gerenciamento-contas/atualizar-id-slack(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_accounts_update_slack_id_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"gerenciamento-contas"],[2,[7,"/"],[2,[6,"atualizar-id-slack"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/gerenciamento-contas/atualizar-id-slack(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_accounts_update_slack_id_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"gerenciamento-contas"],[2,[7,"/"],[2,[6,"atualizar-id-slack"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/leiloes/informacoes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_auctions_all_auctions_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[2,[7,"/"],[2,[6,"informacoes"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/leiloes/informacoes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_auctions_all_auctions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[2,[7,"/"],[2,[6,"informacoes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/leiloes/:url_friendly/atualizar_disponibilidade_lote(.:format)
 * @param {any} url_friendly
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_auctions_change_batch_active_url = __jsr.r({"url_friendly":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[2,[7,"/"],[2,[3,"url_friendly"],[2,[7,"/"],[2,[6,"atualizar_disponibilidade_lote"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/leiloes/:url_friendly/atualizar_disponibilidade_lote(.:format)
 * @param {any} url_friendly
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_auctions_change_batch_active_path = __jsr.r({"url_friendly":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[2,[7,"/"],[2,[3,"url_friendly"],[2,[7,"/"],[2,[6,"atualizar_disponibilidade_lote"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/leiloes/:url_friendly/alternar_permitir_lance(.:format)
 * @param {any} url_friendly
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_auctions_change_bid_permission_url = __jsr.r({"url_friendly":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[2,[7,"/"],[2,[3,"url_friendly"],[2,[7,"/"],[2,[6,"alternar_permitir_lance"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/leiloes/:url_friendly/alternar_permitir_lance(.:format)
 * @param {any} url_friendly
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_auctions_change_bid_permission_path = __jsr.r({"url_friendly":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[2,[7,"/"],[2,[3,"url_friendly"],[2,[7,"/"],[2,[6,"alternar_permitir_lance"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/leiloes/criar_leilao(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_auctions_create_url = __jsr.r({"format":{},"subdomain":{"d":"uploads"}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[2,[7,"/"],[2,[6,"criar_leilao"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/leiloes/criar_leilao(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_auctions_create_path = __jsr.r({"format":{},"subdomain":{"d":"uploads"}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[2,[7,"/"],[2,[6,"criar_leilao"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/leiloes/:url_friendly/excluir_lote(.:format)
 * @param {any} url_friendly
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_auctions_delete_batch_url = __jsr.r({"url_friendly":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[2,[7,"/"],[2,[3,"url_friendly"],[2,[7,"/"],[2,[6,"excluir_lote"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/leiloes/:url_friendly/excluir_lote(.:format)
 * @param {any} url_friendly
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_auctions_delete_batch_path = __jsr.r({"url_friendly":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[2,[7,"/"],[2,[3,"url_friendly"],[2,[7,"/"],[2,[6,"excluir_lote"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/leiloes/:url_friendly/apagar_lp_midia(.:format)
 * @param {any} url_friendly
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_auctions_delete_lp_media_url = __jsr.r({"url_friendly":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[2,[7,"/"],[2,[3,"url_friendly"],[2,[7,"/"],[2,[6,"apagar_lp_midia"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/leiloes/:url_friendly/apagar_lp_midia(.:format)
 * @param {any} url_friendly
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_auctions_delete_lp_media_path = __jsr.r({"url_friendly":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[2,[7,"/"],[2,[3,"url_friendly"],[2,[7,"/"],[2,[6,"apagar_lp_midia"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/leiloes/:url_friendly/excluir_leilao(.:format)
 * @param {any} url_friendly
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_auctions_destroy_url = __jsr.r({"url_friendly":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[2,[7,"/"],[2,[3,"url_friendly"],[2,[7,"/"],[2,[6,"excluir_leilao"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/leiloes/:url_friendly/excluir_leilao(.:format)
 * @param {any} url_friendly
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_auctions_destroy_path = __jsr.r({"url_friendly":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[2,[7,"/"],[2,[3,"url_friendly"],[2,[7,"/"],[2,[6,"excluir_leilao"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/lotes/:auction_id/apagar-todos(.:format)
 * @param {any} auction_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_auctions_destroy_batches_url = __jsr.r({"auction_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"lotes"],[2,[7,"/"],[2,[3,"auction_id"],[2,[7,"/"],[2,[6,"apagar-todos"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/lotes/:auction_id/apagar-todos(.:format)
 * @param {any} auction_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_auctions_destroy_batches_path = __jsr.r({"auction_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"lotes"],[2,[7,"/"],[2,[3,"auction_id"],[2,[7,"/"],[2,[6,"apagar-todos"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/leiloes/:url_friendly/editar_leilao(.:format)
 * @param {any} url_friendly
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_auctions_edit_url = __jsr.r({"url_friendly":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[2,[7,"/"],[2,[3,"url_friendly"],[2,[7,"/"],[2,[6,"editar_leilao"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/leiloes/:url_friendly/editar_leilao(.:format)
 * @param {any} url_friendly
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_auctions_edit_path = __jsr.r({"url_friendly":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[2,[7,"/"],[2,[3,"url_friendly"],[2,[7,"/"],[2,[6,"editar_leilao"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/leiloes/:url_friendly/atualizar_landing_page(.:format)
 * @param {any} url_friendly
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_auctions_edit_landing_page_url = __jsr.r({"url_friendly":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[2,[7,"/"],[2,[3,"url_friendly"],[2,[7,"/"],[2,[6,"atualizar_landing_page"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/leiloes/:url_friendly/atualizar_landing_page(.:format)
 * @param {any} url_friendly
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_auctions_edit_landing_page_path = __jsr.r({"url_friendly":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[2,[7,"/"],[2,[3,"url_friendly"],[2,[7,"/"],[2,[6,"atualizar_landing_page"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/leiloes/europa(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_auctions_europa_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[2,[7,"/"],[2,[6,"europa"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/leiloes/europa(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_auctions_europa_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[2,[7,"/"],[2,[6,"europa"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/leiloes/:url_friendly/exportar_lotes(.:format)
 * @param {any} url_friendly
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_auctions_export_batches_csv_url = __jsr.r({"url_friendly":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[2,[7,"/"],[2,[3,"url_friendly"],[2,[7,"/"],[2,[6,"exportar_lotes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/leiloes/:url_friendly/exportar_lotes(.:format)
 * @param {any} url_friendly
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_auctions_export_batches_csv_path = __jsr.r({"url_friendly":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[2,[7,"/"],[2,[3,"url_friendly"],[2,[7,"/"],[2,[6,"exportar_lotes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/leiloes/:url_friendly/importar_csv(.:format)
 * @param {any} url_friendly
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_auctions_import_csv_url = __jsr.r({"url_friendly":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[2,[7,"/"],[2,[3,"url_friendly"],[2,[7,"/"],[2,[6,"importar_csv"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/leiloes/:url_friendly/importar_csv(.:format)
 * @param {any} url_friendly
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_auctions_import_csv_path = __jsr.r({"url_friendly":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[2,[7,"/"],[2,[3,"url_friendly"],[2,[7,"/"],[2,[6,"importar_csv"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/leiloes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_auctions_index_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /pa/leiloes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_auctions_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /pa/leiloes/:url_friendly/gerenciar_lotes(.:format)
 * @param {any} url_friendly
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_auctions_manage_batches_url = __jsr.r({"url_friendly":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[2,[7,"/"],[2,[3,"url_friendly"],[2,[7,"/"],[2,[6,"gerenciar_lotes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/leiloes/:url_friendly/gerenciar_lotes(.:format)
 * @param {any} url_friendly
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_auctions_manage_batches_path = __jsr.r({"url_friendly":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[2,[7,"/"],[2,[3,"url_friendly"],[2,[7,"/"],[2,[6,"gerenciar_lotes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/leiloes/criar_leilao(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_auctions_new_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[2,[7,"/"],[2,[6,"criar_leilao"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/leiloes/criar_leilao(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_auctions_new_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[2,[7,"/"],[2,[6,"criar_leilao"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/leiloes/:url_friendly/notificar_lancador(.:format)
 * @param {any} url_friendly
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_auctions_notify_bidder_url = __jsr.r({"url_friendly":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[2,[7,"/"],[2,[3,"url_friendly"],[2,[7,"/"],[2,[6,"notificar_lancador"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/leiloes/:url_friendly/notificar_lancador(.:format)
 * @param {any} url_friendly
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_auctions_notify_bidder_path = __jsr.r({"url_friendly":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[2,[7,"/"],[2,[3,"url_friendly"],[2,[7,"/"],[2,[6,"notificar_lancador"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/leiloes/excluir_arquivo(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_auctions_remove_file_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[2,[7,"/"],[2,[6,"excluir_arquivo"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/leiloes/excluir_arquivo(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_auctions_remove_file_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[2,[7,"/"],[2,[6,"excluir_arquivo"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/leiloes/:url_friendly/transmissao_ao_vivo(.:format)
 * @param {any} url_friendly
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_auctions_stream_control_url = __jsr.r({"url_friendly":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[2,[7,"/"],[2,[3,"url_friendly"],[2,[7,"/"],[2,[6,"transmissao_ao_vivo"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/leiloes/:url_friendly/transmissao_ao_vivo(.:format)
 * @param {any} url_friendly
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_auctions_stream_control_path = __jsr.r({"url_friendly":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[2,[7,"/"],[2,[3,"url_friendly"],[2,[7,"/"],[2,[6,"transmissao_ao_vivo"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/leiloes/:url_friendly/espectadores(.:format)
 * @param {any} url_friendly
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_auctions_stream_watchers_url = __jsr.r({"url_friendly":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[2,[7,"/"],[2,[3,"url_friendly"],[2,[7,"/"],[2,[6,"espectadores"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/leiloes/:url_friendly/espectadores(.:format)
 * @param {any} url_friendly
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_auctions_stream_watchers_path = __jsr.r({"url_friendly":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[2,[7,"/"],[2,[3,"url_friendly"],[2,[7,"/"],[2,[6,"espectadores"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/leiloes/informacoes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_auctions_summary_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[2,[7,"/"],[2,[6,"informacoes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/leiloes/informacoes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_auctions_summary_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[2,[7,"/"],[2,[6,"informacoes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/leiloes/:url_friendly/editar_leilao(.:format)
 * @param {any} url_friendly
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_auctions_update_url = __jsr.r({"url_friendly":{"r":true},"format":{},"subdomain":{"d":"uploads"}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[2,[7,"/"],[2,[3,"url_friendly"],[2,[7,"/"],[2,[6,"editar_leilao"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/leiloes/:url_friendly/editar_leilao(.:format)
 * @param {any} url_friendly
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_auctions_update_path = __jsr.r({"url_friendly":{"r":true},"format":{},"subdomain":{"d":"uploads"}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[2,[7,"/"],[2,[3,"url_friendly"],[2,[7,"/"],[2,[6,"editar_leilao"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/leiloes/:url_friendly/atualizar_video_ativo(.:format)
 * @param {any} url_friendly
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_auctions_update_active_video_url = __jsr.r({"url_friendly":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[2,[7,"/"],[2,[3,"url_friendly"],[2,[7,"/"],[2,[6,"atualizar_video_ativo"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/leiloes/:url_friendly/atualizar_video_ativo(.:format)
 * @param {any} url_friendly
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_auctions_update_active_video_path = __jsr.r({"url_friendly":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[2,[7,"/"],[2,[3,"url_friendly"],[2,[7,"/"],[2,[6,"atualizar_video_ativo"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/leiloes/:url_friendly/atualizar_ordem_lotes(.:format)
 * @param {any} url_friendly
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_auctions_update_batches_order_url = __jsr.r({"url_friendly":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[2,[7,"/"],[2,[3,"url_friendly"],[2,[7,"/"],[2,[6,"atualizar_ordem_lotes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/leiloes/:url_friendly/atualizar_ordem_lotes(.:format)
 * @param {any} url_friendly
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_auctions_update_batches_order_path = __jsr.r({"url_friendly":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[2,[7,"/"],[2,[3,"url_friendly"],[2,[7,"/"],[2,[6,"atualizar_ordem_lotes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/leiloes/:url_friendly/marcar_todos_lotes_vendidos(.:format)
 * @param {any} url_friendly
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_auctions_update_batches_status_url = __jsr.r({"url_friendly":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[2,[7,"/"],[2,[3,"url_friendly"],[2,[7,"/"],[2,[6,"marcar_todos_lotes_vendidos"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/leiloes/:url_friendly/marcar_todos_lotes_vendidos(.:format)
 * @param {any} url_friendly
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_auctions_update_batches_status_path = __jsr.r({"url_friendly":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[2,[7,"/"],[2,[3,"url_friendly"],[2,[7,"/"],[2,[6,"marcar_todos_lotes_vendidos"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/leiloes/:url_friendly/atualizar_landing_page(.:format)
 * @param {any} url_friendly
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_auctions_update_landing_page_url = __jsr.r({"url_friendly":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[2,[7,"/"],[2,[3,"url_friendly"],[2,[7,"/"],[2,[6,"atualizar_landing_page"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/leiloes/:url_friendly/atualizar_landing_page(.:format)
 * @param {any} url_friendly
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_auctions_update_landing_page_path = __jsr.r({"url_friendly":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[2,[7,"/"],[2,[3,"url_friendly"],[2,[7,"/"],[2,[6,"atualizar_landing_page"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/leiloes/:url_friendly/atualizar_valor_incremento(.:format)
 * @param {any} url_friendly
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_auctions_update_price_increment_url = __jsr.r({"url_friendly":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[2,[7,"/"],[2,[3,"url_friendly"],[2,[7,"/"],[2,[6,"atualizar_valor_incremento"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/leiloes/:url_friendly/atualizar_valor_incremento(.:format)
 * @param {any} url_friendly
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_auctions_update_price_increment_path = __jsr.r({"url_friendly":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[2,[7,"/"],[2,[3,"url_friendly"],[2,[7,"/"],[2,[6,"atualizar_valor_incremento"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/leiloes/:url_friendly/atualizar_informacao_stream(.:format)
 * @param {any} url_friendly
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_auctions_update_stream_info_url = __jsr.r({"url_friendly":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[2,[7,"/"],[2,[3,"url_friendly"],[2,[7,"/"],[2,[6,"atualizar_informacao_stream"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/leiloes/:url_friendly/atualizar_informacao_stream(.:format)
 * @param {any} url_friendly
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_auctions_update_stream_info_path = __jsr.r({"url_friendly":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[2,[7,"/"],[2,[3,"url_friendly"],[2,[7,"/"],[2,[6,"atualizar_informacao_stream"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/lotes/encerrar_lances(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_batches_close_bidding_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"lotes"],[2,[7,"/"],[2,[6,"encerrar_lances"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/lotes/encerrar_lances(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_batches_close_bidding_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"lotes"],[2,[7,"/"],[2,[6,"encerrar_lances"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/lotes/apagar_imagem(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_batches_delete_aditional_photo_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"lotes"],[2,[7,"/"],[2,[6,"apagar_imagem"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/lotes/apagar_imagem(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_batches_delete_aditional_photo_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"lotes"],[2,[7,"/"],[2,[6,"apagar_imagem"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/leiloes/lotes/excluir_animal(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_batches_delete_animal_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[2,[7,"/"],[2,[6,"lotes"],[2,[7,"/"],[2,[6,"excluir_animal"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/leiloes/lotes/excluir_animal(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_batches_delete_animal_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[2,[7,"/"],[2,[6,"lotes"],[2,[7,"/"],[2,[6,"excluir_animal"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/lotes/:url_friendly/apagar_cover_img(.:format)
 * @param {any} url_friendly
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_batches_delete_cover_img_url = __jsr.r({"url_friendly":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"lotes"],[2,[7,"/"],[2,[3,"url_friendly"],[2,[7,"/"],[2,[6,"apagar_cover_img"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/lotes/:url_friendly/apagar_cover_img(.:format)
 * @param {any} url_friendly
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_batches_delete_cover_img_path = __jsr.r({"url_friendly":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"lotes"],[2,[7,"/"],[2,[3,"url_friendly"],[2,[7,"/"],[2,[6,"apagar_cover_img"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/pesquisar_lote(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_batches_lookup_batch_info_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"pesquisar_lote"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /pa/pesquisar_lote(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_batches_lookup_batch_info_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"pesquisar_lote"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /pa/leiloes/:url_friendly/salvar_lote(.:format)
 * @param {any} url_friendly
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_batches_save_url = __jsr.r({"url_friendly":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[2,[7,"/"],[2,[3,"url_friendly"],[2,[7,"/"],[2,[6,"salvar_lote"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/leiloes/:url_friendly/salvar_lote(.:format)
 * @param {any} url_friendly
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_batches_save_path = __jsr.r({"url_friendly":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[2,[7,"/"],[2,[3,"url_friendly"],[2,[7,"/"],[2,[6,"salvar_lote"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/leiloes/crawlear-lote(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_batches_scrape_all_from_batch_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[2,[7,"/"],[2,[6,"crawlear-lote"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/leiloes/crawlear-lote(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_batches_scrape_all_from_batch_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[2,[7,"/"],[2,[6,"crawlear-lote"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/leiloes/crawlear-evento(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_batches_scrape_all_from_event_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[2,[7,"/"],[2,[6,"crawlear-evento"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/leiloes/crawlear-evento(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_batches_scrape_all_from_event_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[2,[7,"/"],[2,[6,"crawlear-evento"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/leiloes/crawlear-org(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_batches_scrape_animal_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[2,[7,"/"],[2,[6,"crawlear-org"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/leiloes/crawlear-org(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_batches_scrape_animal_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes"],[2,[7,"/"],[2,[6,"crawlear-org"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/lotes/:batch_id/crawler-status(.:format)
 * @param {any} batch_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_batches_scrapping_status_url = __jsr.r({"batch_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"lotes"],[2,[7,"/"],[2,[3,"batch_id"],[2,[7,"/"],[2,[6,"crawler-status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/lotes/:batch_id/crawler-status(.:format)
 * @param {any} batch_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_batches_scrapping_status_path = __jsr.r({"batch_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"lotes"],[2,[7,"/"],[2,[3,"batch_id"],[2,[7,"/"],[2,[6,"crawler-status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/lotes/valor_inicial(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_batches_set_initial_price_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"lotes"],[2,[7,"/"],[2,[6,"valor_inicial"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/lotes/valor_inicial(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_batches_set_initial_price_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"lotes"],[2,[7,"/"],[2,[6,"valor_inicial"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/retornar_lote(/:id)(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_batches_show_url = __jsr.r({"id":{},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"retornar_lote"],[2,[1,[2,[7,"/"],[3,"id"]]],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/retornar_lote(/:id)(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_batches_show_path = __jsr.r({"id":{},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"retornar_lote"],[2,[1,[2,[7,"/"],[3,"id"]]],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * /pa/exportar_dados/pipes-comerciais(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_data_exports_export_commercial_interactions_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"exportar_dados"],[2,[7,"/"],[2,[6,"pipes-comerciais"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/exportar_dados/pipes-comerciais(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_data_exports_export_commercial_interactions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"exportar_dados"],[2,[7,"/"],[2,[6,"pipes-comerciais"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/exportar_dados/status-comercial(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_data_exports_export_commercial_status_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"exportar_dados"],[2,[7,"/"],[2,[6,"status-comercial"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/exportar_dados/status-comercial(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_data_exports_export_commercial_status_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"exportar_dados"],[2,[7,"/"],[2,[6,"status-comercial"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/exportar_dados/conversoes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_data_exports_export_conversions_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"exportar_dados"],[2,[7,"/"],[2,[6,"conversoes"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/exportar_dados/conversoes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_data_exports_export_conversions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"exportar_dados"],[2,[7,"/"],[2,[6,"conversoes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/exportar_dados/interacoes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_data_exports_export_interactions_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"exportar_dados"],[2,[7,"/"],[2,[6,"interacoes"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/exportar_dados/interacoes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_data_exports_export_interactions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"exportar_dados"],[2,[7,"/"],[2,[6,"interacoes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/exportar_dados/compatibilidade-leads-leiloes-ativos-por-cm(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_data_exports_export_leads_active_auctions_match_by_cm_url = __jsr.r({"format":{},"subdomain":{"d":"uploads"}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"exportar_dados"],[2,[7,"/"],[2,[6,"compatibilidade-leads-leiloes-ativos-por-cm"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/exportar_dados/compatibilidade-leads-leiloes-ativos-por-cm(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_data_exports_export_leads_active_auctions_match_by_cm_path = __jsr.r({"format":{},"subdomain":{"d":"uploads"}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"exportar_dados"],[2,[7,"/"],[2,[6,"compatibilidade-leads-leiloes-ativos-por-cm"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/exportar_dados/compatibilidade-leads-leilao(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_data_exports_export_leads_auction_match_url = __jsr.r({"format":{},"subdomain":{"d":"uploads"}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"exportar_dados"],[2,[7,"/"],[2,[6,"compatibilidade-leads-leilao"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/exportar_dados/compatibilidade-leads-leilao(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_data_exports_export_leads_auction_match_path = __jsr.r({"format":{},"subdomain":{"d":"uploads"}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"exportar_dados"],[2,[7,"/"],[2,[6,"compatibilidade-leads-leilao"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/exportar_dados/ofertas(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_data_exports_export_offers_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"exportar_dados"],[2,[7,"/"],[2,[6,"ofertas"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/exportar_dados/ofertas(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_data_exports_export_offers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"exportar_dados"],[2,[7,"/"],[2,[6,"ofertas"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/exportar_dados/comportamento_pre_compra(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_data_exports_export_pre_purchase_behavior_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"exportar_dados"],[2,[7,"/"],[2,[6,"comportamento_pre_compra"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/exportar_dados/comportamento_pre_compra(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_data_exports_export_pre_purchase_behavior_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"exportar_dados"],[2,[7,"/"],[2,[6,"comportamento_pre_compra"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/exportar_dados/vendas(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_data_exports_export_sales_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"exportar_dados"],[2,[7,"/"],[2,[6,"vendas"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/exportar_dados/vendas(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_data_exports_export_sales_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"exportar_dados"],[2,[7,"/"],[2,[6,"vendas"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/exportar_dados(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_data_exports_index_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"exportar_dados"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /pa/exportar_dados(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_data_exports_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"exportar_dados"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /agenda_leiloes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const agenda_leiloes_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"agenda_leiloes"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /agenda_leiloes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const agenda_leiloes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"agenda_leiloes"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /alerta-ofertas/cancelar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alert_subscriptions_confirm_unsubscribe_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"alerta-ofertas"],[2,[7,"/"],[2,[6,"cancelar"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /alerta-ofertas/cancelar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alert_subscriptions_confirm_unsubscribe_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"alerta-ofertas"],[2,[7,"/"],[2,[6,"cancelar"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /alerta-ofertas/criar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alert_subscriptions_create_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"alerta-ofertas"],[2,[7,"/"],[2,[6,"criar"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /alerta-ofertas/criar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alert_subscriptions_create_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"alerta-ofertas"],[2,[7,"/"],[2,[6,"criar"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /alerta-ofertas/cancelar/:token(.:format)
 * @param {any} token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alert_subscriptions_unsubscribe_url = __jsr.r({"token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"alerta-ofertas"],[2,[7,"/"],[2,[6,"cancelar"],[2,[7,"/"],[2,[3,"token"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /alerta-ofertas/cancelar/:token(.:format)
 * @param {any} token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alert_subscriptions_unsubscribe_path = __jsr.r({"token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"alerta-ofertas"],[2,[7,"/"],[2,[6,"cancelar"],[2,[7,"/"],[2,[3,"token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /lotes/descrever-animal/:animal_id(.:format)
 * @param {any} animal_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const animal_ai_descriptions_describe_animal_url = __jsr.r({"animal_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"lotes"],[2,[7,"/"],[2,[6,"descrever-animal"],[2,[7,"/"],[2,[3,"animal_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /lotes/descrever-animal/:animal_id(.:format)
 * @param {any} animal_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const animal_ai_descriptions_describe_animal_path = __jsr.r({"animal_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"lotes"],[2,[7,"/"],[2,[6,"descrever-animal"],[2,[7,"/"],[2,[3,"animal_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /lotes/descrever-animal/:animal_id/:animal_description_id/publicar(.:format)
 * @param {any} animal_id
 * @param {any} animal_description_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const animal_ai_descriptions_save_description_url = __jsr.r({"animal_id":{"r":true},"animal_description_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"lotes"],[2,[7,"/"],[2,[6,"descrever-animal"],[2,[7,"/"],[2,[3,"animal_id"],[2,[7,"/"],[2,[3,"animal_description_id"],[2,[7,"/"],[2,[6,"publicar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /lotes/descrever-animal/:animal_id/:animal_description_id/publicar(.:format)
 * @param {any} animal_id
 * @param {any} animal_description_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const animal_ai_descriptions_save_description_path = __jsr.r({"animal_id":{"r":true},"animal_description_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"lotes"],[2,[7,"/"],[2,[6,"descrever-animal"],[2,[7,"/"],[2,[3,"animal_id"],[2,[7,"/"],[2,[3,"animal_description_id"],[2,[7,"/"],[2,[6,"publicar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /categorias(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_categories_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"categorias"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /categorias(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_categories_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"categorias"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /lista_ddi(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_fetch_country_ddi_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"lista_ddi"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /lista_ddi(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_fetch_country_ddi_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"lista_ddi"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /usuario-autenticado(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_fetch_current_user_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"usuario-autenticado"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /usuario-autenticado(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_fetch_current_user_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"usuario-autenticado"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /enviar-conversao-facebook(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_send_fcb_conversion_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"enviar-conversao-facebook"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /enviar-conversao-facebook(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_send_fcb_conversion_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"enviar-conversao-facebook"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /wpp-gc(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_whatsapp_commercial_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"wpp-gc"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /wpp-gc(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_whatsapp_commercial_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"wpp-gc"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /evento/:url_friendly/ver_mais(/:page)(.:format)
 * @param {any} url_friendly
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const auctions_see_more_url = __jsr.r({"url_friendly":{"r":true},"page":{},"format":{}}, [2,[7,"/"],[2,[6,"evento"],[2,[7,"/"],[2,[3,"url_friendly"],[2,[7,"/"],[2,[6,"ver_mais"],[2,[1,[2,[7,"/"],[3,"page"]]],[1,[2,[8,"."],[3,"format"]]]]]]]]]], true);

/**
 * Generates rails route to
 * /evento/:url_friendly/ver_mais(/:page)(.:format)
 * @param {any} url_friendly
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const auctions_see_more_path = __jsr.r({"url_friendly":{"r":true},"page":{},"format":{}}, [2,[7,"/"],[2,[6,"evento"],[2,[7,"/"],[2,[3,"url_friendly"],[2,[7,"/"],[2,[6,"ver_mais"],[2,[1,[2,[7,"/"],[3,"page"]]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]);

/**
 * Generates rails route to
 * /evento/:url_friendly(.:format)
 * @param {any} url_friendly
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const auctions_show_url = __jsr.r({"url_friendly":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"evento"],[2,[7,"/"],[2,[3,"url_friendly"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /evento/:url_friendly(.:format)
 * @param {any} url_friendly
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const auctions_show_path = __jsr.r({"url_friendly":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"evento"],[2,[7,"/"],[2,[3,"url_friendly"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /evento/:url_friendly/lp(.:format)
 * @param {any} url_friendly
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const auctions_show_landing_page_url = __jsr.r({"url_friendly":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"evento"],[2,[7,"/"],[2,[3,"url_friendly"],[2,[7,"/"],[2,[6,"lp"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /evento/:url_friendly/lp(.:format)
 * @param {any} url_friendly
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const auctions_show_landing_page_path = __jsr.r({"url_friendly":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"evento"],[2,[7,"/"],[2,[3,"url_friendly"],[2,[7,"/"],[2,[6,"lp"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /evento/:url_friendly/ao-vivo(.:format)
 * @param {any} url_friendly
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const auctions_show_live_url = __jsr.r({"url_friendly":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"evento"],[2,[7,"/"],[2,[3,"url_friendly"],[2,[7,"/"],[2,[6,"ao-vivo"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /evento/:url_friendly/ao-vivo(.:format)
 * @param {any} url_friendly
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const auctions_show_live_path = __jsr.r({"url_friendly":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"evento"],[2,[7,"/"],[2,[3,"url_friendly"],[2,[7,"/"],[2,[6,"ao-vivo"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /evento/:url_friendly/reels(/:batch_number)(.:format)
 * @param {any} url_friendly
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const auctions_show_reels_url = __jsr.r({"url_friendly":{"r":true},"batch_number":{},"format":{}}, [2,[7,"/"],[2,[6,"evento"],[2,[7,"/"],[2,[3,"url_friendly"],[2,[7,"/"],[2,[6,"reels"],[2,[1,[2,[7,"/"],[3,"batch_number"]]],[1,[2,[8,"."],[3,"format"]]]]]]]]]], true);

/**
 * Generates rails route to
 * /evento/:url_friendly/reels(/:batch_number)(.:format)
 * @param {any} url_friendly
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const auctions_show_reels_path = __jsr.r({"url_friendly":{"r":true},"batch_number":{},"format":{}}, [2,[7,"/"],[2,[6,"evento"],[2,[7,"/"],[2,[3,"url_friendly"],[2,[7,"/"],[2,[6,"reels"],[2,[1,[2,[7,"/"],[3,"batch_number"]]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]);

/**
 * Generates rails route to
 * /evento/:url_friendly/lotes/:url_friendly_batch(.:format)
 * @param {any} url_friendly
 * @param {any} url_friendly_batch
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const batches_show_url = __jsr.r({"url_friendly":{"r":true},"url_friendly_batch":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"evento"],[2,[7,"/"],[2,[3,"url_friendly"],[2,[7,"/"],[2,[6,"lotes"],[2,[7,"/"],[2,[3,"url_friendly_batch"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /evento/:url_friendly/lotes/:url_friendly_batch(.:format)
 * @param {any} url_friendly
 * @param {any} url_friendly_batch
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const batches_show_path = __jsr.r({"url_friendly":{"r":true},"url_friendly_batch":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"evento"],[2,[7,"/"],[2,[3,"url_friendly"],[2,[7,"/"],[2,[6,"lotes"],[2,[7,"/"],[2,[3,"url_friendly_batch"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /biometria/link/:customer_id(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const biometries_create_url = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"biometria"],[2,[7,"/"],[2,[6,"link"],[2,[7,"/"],[2,[3,"customer_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /biometria/link/:customer_id(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const biometries_create_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"biometria"],[2,[7,"/"],[2,[6,"link"],[2,[7,"/"],[2,[3,"customer_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /biometria/status/webhook(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const biometries_status_webhook_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"biometria"],[2,[7,"/"],[2,[6,"status"],[2,[7,"/"],[2,[6,"webhook"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /biometria/status/webhook(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const biometries_status_webhook_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"biometria"],[2,[7,"/"],[2,[6,"status"],[2,[7,"/"],[2,[6,"webhook"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /blog(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blog_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"blog"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /blog(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blog_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"blog"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /conteudo/ver_mais(/:url_friendly)(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blog_see_more_url = __jsr.r({"url_friendly":{},"format":{}}, [2,[7,"/"],[2,[6,"conteudo"],[2,[7,"/"],[2,[6,"ver_mais"],[2,[1,[2,[7,"/"],[3,"url_friendly"]]],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * /conteudo/ver_mais(/:url_friendly)(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blog_see_more_path = __jsr.r({"url_friendly":{},"format":{}}, [2,[7,"/"],[2,[6,"conteudo"],[2,[7,"/"],[2,[6,"ver_mais"],[2,[1,[2,[7,"/"],[3,"url_friendly"]]],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * /pa/marketing/campanhas/criar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const campaigns_create_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"marketing"],[2,[7,"/"],[2,[6,"campanhas"],[2,[7,"/"],[2,[6,"criar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/marketing/campanhas/criar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const campaigns_create_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"marketing"],[2,[7,"/"],[2,[6,"campanhas"],[2,[7,"/"],[2,[6,"criar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/marketing/campanhas/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const campaigns_destroy_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"marketing"],[2,[7,"/"],[2,[6,"campanhas"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/marketing/campanhas/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const campaigns_destroy_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"marketing"],[2,[7,"/"],[2,[6,"campanhas"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/marketing/campanhas/:id/duplicar(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const campaigns_duplicate_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"marketing"],[2,[7,"/"],[2,[6,"campanhas"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"duplicar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/marketing/campanhas/:id/duplicar(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const campaigns_duplicate_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"marketing"],[2,[7,"/"],[2,[6,"campanhas"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"duplicar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/marketing/campanhas/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const campaigns_edit_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"marketing"],[2,[7,"/"],[2,[6,"campanhas"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/marketing/campanhas/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const campaigns_edit_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"marketing"],[2,[7,"/"],[2,[6,"campanhas"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /ofertas(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const campaigns_index_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ofertas"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /ofertas(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const campaigns_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ofertas"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /pa/marketing/campanhas(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const campaigns_manage_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"marketing"],[2,[7,"/"],[2,[6,"campanhas"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/marketing/campanhas(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const campaigns_manage_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"marketing"],[2,[7,"/"],[2,[6,"campanhas"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/marketing/campanhas/criar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const campaigns_new_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"marketing"],[2,[7,"/"],[2,[6,"campanhas"],[2,[7,"/"],[2,[6,"criar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/marketing/campanhas/criar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const campaigns_new_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"marketing"],[2,[7,"/"],[2,[6,"campanhas"],[2,[7,"/"],[2,[6,"criar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /ofertas/:url_friendly(.:format)
 * @param {any} url_friendly
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const campaigns_show_url = __jsr.r({"url_friendly":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ofertas"],[2,[7,"/"],[2,[3,"url_friendly"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /ofertas/:url_friendly(.:format)
 * @param {any} url_friendly
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const campaigns_show_path = __jsr.r({"url_friendly":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ofertas"],[2,[7,"/"],[2,[3,"url_friendly"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /pa/marketing/campanhas/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const campaigns_update_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"marketing"],[2,[7,"/"],[2,[6,"campanhas"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/marketing/campanhas/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const campaigns_update_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"marketing"],[2,[7,"/"],[2,[6,"campanhas"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/cancel(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancel_user_registration_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /users/cancel(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancel_user_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/catalogo(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const catalog_index_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"catalogo"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/catalogo(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const catalog_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"catalogo"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /chatbot/atributos_cliente(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chatbot_answers_create_from_chatbot_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"chatbot"],[2,[7,"/"],[2,[6,"atributos_cliente"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /chatbot/atributos_cliente(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chatbot_answers_create_from_chatbot_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"chatbot"],[2,[7,"/"],[2,[6,"atributos_cliente"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/anexos/:id/apagar(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chatbot_answers_destroy_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"anexos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"apagar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/anexos/:id/apagar(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chatbot_answers_destroy_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"anexos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"apagar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/anexos/:id(/:style)(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chatbot_answers_show_attachment_url = __jsr.r({"id":{"r":true},"style":{},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"anexos"],[2,[7,"/"],[2,[3,"id"],[2,[1,[2,[7,"/"],[3,"style"]]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/anexos/:id(/:style)(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chatbot_answers_show_attachment_path = __jsr.r({"id":{"r":true},"style":{},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"anexos"],[2,[7,"/"],[2,[3,"id"],[2,[1,[2,[7,"/"],[3,"style"]]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /chatbot/segmentacao(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chatbot_answers_show_chatbot_segmentation_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"chatbot"],[2,[7,"/"],[2,[6,"segmentacao"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /chatbot/segmentacao(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chatbot_answers_show_chatbot_segmentation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"chatbot"],[2,[7,"/"],[2,[6,"segmentacao"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /pa/chatbots(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chatbots_create_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"chatbots"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /pa/chatbots(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chatbots_create_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"chatbots"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /pa/chatbots/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chatbots_destroy_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"chatbots"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/chatbots/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chatbots_destroy_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"chatbots"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/chatbots/duplicate/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chatbots_duplicate_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"chatbots"],[2,[7,"/"],[2,[6,"duplicate"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/chatbots/duplicate/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chatbots_duplicate_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"chatbots"],[2,[7,"/"],[2,[6,"duplicate"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/chatbots/editar/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chatbots_edit_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"chatbots"],[2,[7,"/"],[2,[6,"editar"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/chatbots/editar/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chatbots_edit_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"chatbots"],[2,[7,"/"],[2,[6,"editar"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/chatbots(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chatbots_index_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"chatbots"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /pa/chatbots(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chatbots_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"chatbots"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /chatbots/:url_friendly(.:format)
 * @param {any} url_friendly
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chatbots_show_url = __jsr.r({"url_friendly":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"chatbots"],[2,[7,"/"],[2,[3,"url_friendly"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /chatbots/:url_friendly(.:format)
 * @param {any} url_friendly
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chatbots_show_path = __jsr.r({"url_friendly":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"chatbots"],[2,[7,"/"],[2,[3,"url_friendly"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /pa/chatbots(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chatbots_update_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"chatbots"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /pa/chatbots(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chatbots_update_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"chatbots"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /chat/criar_access_token(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chats_create_access_token_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"chat"],[2,[7,"/"],[2,[6,"criar_access_token"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /chat/criar_access_token(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chats_create_access_token_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"chat"],[2,[7,"/"],[2,[6,"criar_access_token"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /chat/twilio/identity/busca(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chats_fetch_twilio_identity_name_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"chat"],[2,[7,"/"],[2,[6,"twilio"],[2,[7,"/"],[2,[6,"identity"],[2,[7,"/"],[2,[6,"busca"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /chat/twilio/identity/busca(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chats_fetch_twilio_identity_name_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"chat"],[2,[7,"/"],[2,[6,"twilio"],[2,[7,"/"],[2,[6,"identity"],[2,[7,"/"],[2,[6,"busca"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /chat/renderizar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chats_render_chat_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"chat"],[2,[7,"/"],[2,[6,"renderizar"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /chat/renderizar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chats_render_chat_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"chat"],[2,[7,"/"],[2,[6,"renderizar"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /confirm_notification_subscription(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const confirm_notification_subscription_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"confirm_notification_subscription"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /confirm_notification_subscription(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const confirm_notification_subscription_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"confirm_notification_subscription"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /fale_conosco(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const contact_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"fale_conosco"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /fale_conosco(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const contact_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"fale_conosco"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /politica_cookies(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cookies_policies_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"politica_cookies"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /politica_cookies(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cookies_policies_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"politica_cookies"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /pa/cursos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const courses_create_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"cursos"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /pa/cursos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const courses_create_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"cursos"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /pa/cursos/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const courses_destroy_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"cursos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/cursos/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const courses_destroy_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"cursos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/curso/editar/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const courses_edit_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"curso"],[2,[7,"/"],[2,[6,"editar"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/curso/editar/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const courses_edit_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"curso"],[2,[7,"/"],[2,[6,"editar"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/cursos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const courses_index_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"cursos"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /pa/cursos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const courses_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"cursos"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /curso(/:url_friendly)(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const courses_show_url = __jsr.r({"url_friendly":{},"format":{}}, [2,[7,"/"],[2,[6,"curso"],[2,[1,[2,[7,"/"],[3,"url_friendly"]]],[1,[2,[8,"."],[3,"format"]]]]]], true);

/**
 * Generates rails route to
 * /curso(/:url_friendly)(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const courses_show_path = __jsr.r({"url_friendly":{},"format":{}}, [2,[7,"/"],[2,[6,"curso"],[2,[1,[2,[7,"/"],[3,"url_friendly"]]],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pa/cursos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const courses_update_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"cursos"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /pa/cursos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const courses_update_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"cursos"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /pa/leiloes_crawleados/:id/deletar(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const crawled_auction_destroy_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes_crawleados"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"deletar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/leiloes_crawleados/:id/deletar(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const crawled_auction_destroy_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes_crawleados"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"deletar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/leiloes_crawleados/atualizar_atributo(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const crawled_auction_update_attribute_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes_crawleados"],[2,[7,"/"],[2,[6,"atualizar_atributo"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/leiloes_crawleados/atualizar_atributo(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const crawled_auction_update_attribute_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes_crawleados"],[2,[7,"/"],[2,[6,"atualizar_atributo"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/leiloes_crawleados/:id/editar(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const crawled_auctions_edit_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes_crawleados"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"editar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/leiloes_crawleados/:id/editar(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const crawled_auctions_edit_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes_crawleados"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"editar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /agenda-eventos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const crawled_auctions_index_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"agenda-eventos"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /agenda-eventos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const crawled_auctions_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"agenda-eventos"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /pa/leiloes_crawleados/:type(/:filter)(.:format)
 * @param {any} type
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const crawled_auctions_manage_url = __jsr.r({"type":{"r":true},"filter":{},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes_crawleados"],[2,[7,"/"],[2,[3,"type"],[2,[1,[2,[7,"/"],[3,"filter"]]],[1,[2,[8,"."],[3,"format"]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/leiloes_crawleados/:type(/:filter)(.:format)
 * @param {any} type
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const crawled_auctions_manage_path = __jsr.r({"type":{"r":true},"filter":{},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes_crawleados"],[2,[7,"/"],[2,[3,"type"],[2,[1,[2,[7,"/"],[3,"filter"]]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]);

/**
 * Generates rails route to
 * /agenda-eventos/ultimos_leiloes_por_raca(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const crawled_auctions_sample_by_breed_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"agenda-eventos"],[2,[7,"/"],[2,[6,"ultimos_leiloes_por_raca"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /agenda-eventos/ultimos_leiloes_por_raca(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const crawled_auctions_sample_by_breed_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"agenda-eventos"],[2,[7,"/"],[2,[6,"ultimos_leiloes_por_raca"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /agenda-eventos/lembrete(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const crawled_auctions_schedule_reminder_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"agenda-eventos"],[2,[7,"/"],[2,[6,"lembrete"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /agenda-eventos/lembrete(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const crawled_auctions_schedule_reminder_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"agenda-eventos"],[2,[7,"/"],[2,[6,"lembrete"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /agenda-eventos/filtrar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const crawled_auctions_search_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"agenda-eventos"],[2,[7,"/"],[2,[6,"filtrar"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /agenda-eventos/filtrar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const crawled_auctions_search_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"agenda-eventos"],[2,[7,"/"],[2,[6,"filtrar"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /pa/leiloes_crawleados/:id/atualizar(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const crawled_auctions_update_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes_crawleados"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"atualizar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/leiloes_crawleados/:id/atualizar(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const crawled_auctions_update_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"leiloes_crawleados"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"atualizar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/novo_artigo_blog(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_article_url = __jsr.r({"format":{},"subdomain":{"d":"uploads"}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"novo_artigo_blog"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /pa/novo_artigo_blog(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_article_path = __jsr.r({"format":{},"subdomain":{"d":"uploads"}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"novo_artigo_blog"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /create_bank_account(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_bank_account_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"create_bank_account"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /create_bank_account(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_bank_account_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"create_bank_account"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /auctions/create_bid(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_bid_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"auctions"],[2,[7,"/"],[2,[6,"create_bid"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /auctions/create_bid(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_bid_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"auctions"],[2,[7,"/"],[2,[6,"create_bid"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /aovivo/usuarios(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_chat_user_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"aovivo"],[2,[7,"/"],[2,[6,"usuarios"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /aovivo/usuarios(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_chat_user_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"aovivo"],[2,[7,"/"],[2,[6,"usuarios"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /pa/campanhas_home_page/create(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_home_campaign_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"campanhas_home_page"],[2,[7,"/"],[2,[6,"create"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/campanhas_home_page/create(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_home_campaign_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"campanhas_home_page"],[2,[7,"/"],[2,[6,"create"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/aovivo(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_livestream_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"aovivo"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /pa/aovivo(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_livestream_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"aovivo"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /create_notification_subscription(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_notification_subscription_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"create_notification_subscription"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /create_notification_subscription(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_notification_subscription_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"create_notification_subscription"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /pa/usuarios/create_link(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_tracked_user_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"usuarios"],[2,[7,"/"],[2,[6,"create_link"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/usuarios/create_link(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_tracked_user_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"usuarios"],[2,[7,"/"],[2,[6,"create_link"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/credit-policy(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const credit_policy_query_create_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"credit-policy"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /pa/credit-policy(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const credit_policy_query_create_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"credit-policy"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /pa/credit-policy/:customer_id(/:query_id)(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const credit_policy_query_show_url = __jsr.r({"customer_id":{"r":true},"query_id":{},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"credit-policy"],[2,[7,"/"],[2,[3,"customer_id"],[2,[1,[2,[7,"/"],[3,"query_id"]]],[1,[2,[8,"."],[3,"format"]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/credit-policy/:customer_id(/:query_id)(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const credit_policy_query_show_path = __jsr.r({"customer_id":{"r":true},"query_id":{},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"credit-policy"],[2,[7,"/"],[2,[3,"customer_id"],[2,[1,[2,[7,"/"],[3,"query_id"]]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/atributos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const crm_attributes_create_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"atributos"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/atributos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const crm_attributes_create_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"atributos"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/atributos(/:id)(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const crm_attributes_destroy_url = __jsr.r({"id":{},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"atributos"],[2,[1,[2,[7,"/"],[3,"id"]]],[1,[2,[8,"."],[3,"format"]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/atributos(/:id)(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const crm_attributes_destroy_path = __jsr.r({"id":{},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"atributos"],[2,[1,[2,[7,"/"],[3,"id"]]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/atributos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const crm_attributes_index_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"atributos"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/atributos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const crm_attributes_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"atributos"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/atributos/info/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const crm_attributes_infos_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"atributos"],[2,[7,"/"],[2,[6,"info"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/atributos/info/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const crm_attributes_infos_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"atributos"],[2,[7,"/"],[2,[6,"info"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/atributos(/:id)(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const crm_attributes_show_url = __jsr.r({"id":{},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"atributos"],[2,[1,[2,[7,"/"],[3,"id"]]],[1,[2,[8,"."],[3,"format"]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/atributos(/:id)(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const crm_attributes_show_path = __jsr.r({"id":{},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"atributos"],[2,[1,[2,[7,"/"],[3,"id"]]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/atributos(/:id)(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const crm_attributes_update_url = __jsr.r({"id":{},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"atributos"],[2,[1,[2,[7,"/"],[3,"id"]]],[1,[2,[8,"."],[3,"format"]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/atributos(/:id)(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const crm_attributes_update_path = __jsr.r({"id":{},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"atributos"],[2,[1,[2,[7,"/"],[3,"id"]]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/customer/atributos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_attributes_create_or_update_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"atributos"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/customer/atributos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_attributes_create_or_update_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"atributos"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/customer/atributos/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_attributes_destroy_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"atributos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/customer/atributos/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_attributes_destroy_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"atributos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/customer/atributos/:id/valores(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_attributes_values_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"atributos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"valores"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/customer/atributos/:id/valores(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_attributes_values_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"atributos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"valores"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/documentos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_documents_create_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"documentos"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/documentos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_documents_create_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"documentos"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/documentos/:id/apagar(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_documents_destroy_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"documentos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"apagar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/documentos/:id/apagar(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_documents_destroy_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"documentos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"apagar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/documentos/:id(/:style)(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_documents_show_url = __jsr.r({"id":{"r":true},"style":{},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"documentos"],[2,[7,"/"],[2,[3,"id"],[2,[1,[2,[7,"/"],[3,"style"]]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/documentos/:id(/:style)(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_documents_show_path = __jsr.r({"id":{"r":true},"style":{},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"documentos"],[2,[7,"/"],[2,[3,"id"],[2,[1,[2,[7,"/"],[3,"style"]]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /customer-feedbacks/answers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_feedbacks_receive_answers_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer-feedbacks"],[2,[7,"/"],[2,[6,"answers"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /customer-feedbacks/answers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_feedbacks_receive_answers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer-feedbacks"],[2,[7,"/"],[2,[6,"answers"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/filtros(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_filters_search_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"filtros"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/filtros(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_filters_search_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"filtros"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/clientes/info/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_infos_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"clientes"],[2,[7,"/"],[2,[6,"info"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/clientes/info/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_infos_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"clientes"],[2,[7,"/"],[2,[6,"info"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/interacoes/deletar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_interactions_destroy_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"interacoes"],[2,[7,"/"],[2,[6,"deletar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/interacoes/deletar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_interactions_destroy_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"interacoes"],[2,[7,"/"],[2,[6,"deletar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/lista/acao-em-massa(/:id)(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_lists_bulk_updates_url = __jsr.r({"id":{},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"lista"],[2,[7,"/"],[2,[6,"acao-em-massa"],[2,[1,[2,[7,"/"],[3,"id"]]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/lista/acao-em-massa(/:id)(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_lists_bulk_updates_path = __jsr.r({"id":{},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"lista"],[2,[7,"/"],[2,[6,"acao-em-massa"],[2,[1,[2,[7,"/"],[3,"id"]]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/lista(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_lists_create_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"lista"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/lista(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_lists_create_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"lista"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/lista(/:id)(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_lists_destroy_url = __jsr.r({"id":{},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"lista"],[2,[1,[2,[7,"/"],[3,"id"]]],[1,[2,[8,"."],[3,"format"]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/lista(/:id)(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_lists_destroy_path = __jsr.r({"id":{},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"lista"],[2,[1,[2,[7,"/"],[3,"id"]]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/lista/exportar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_lists_export_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"lista"],[2,[7,"/"],[2,[6,"exportar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/lista/exportar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_lists_export_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"lista"],[2,[7,"/"],[2,[6,"exportar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/lista/:id/filtros(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_lists_filters_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"lista"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"filtros"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/lista/:id/filtros(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_lists_filters_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"lista"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"filtros"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/lista/importar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_lists_import_list_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"lista"],[2,[7,"/"],[2,[6,"importar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/lista/importar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_lists_import_list_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"lista"],[2,[7,"/"],[2,[6,"importar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/lista(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_lists_index_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"lista"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/lista(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_lists_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"lista"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/lista/buscar_leads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_lists_search_customers_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"lista"],[2,[7,"/"],[2,[6,"buscar_leads"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/lista/buscar_leads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_lists_search_customers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"lista"],[2,[7,"/"],[2,[6,"buscar_leads"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/lista/buscar_listas(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_lists_search_lists_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"lista"],[2,[7,"/"],[2,[6,"buscar_listas"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/lista/buscar_listas(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_lists_search_lists_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"lista"],[2,[7,"/"],[2,[6,"buscar_listas"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/lista/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_lists_show_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"lista"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/lista/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_lists_show_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"lista"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/lista/:id/favoritar(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_lists_toggle_favorite_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"lista"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"favoritar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/lista/:id/favoritar(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_lists_toggle_favorite_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"lista"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"favoritar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/lista/editar/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_lists_update_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"lista"],[2,[7,"/"],[2,[6,"editar"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/lista/editar/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_lists_update_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"lista"],[2,[7,"/"],[2,[6,"editar"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/negociacoes/usuarios-responsaveis(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_negotiations_available_responsibles_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"negociacoes"],[2,[7,"/"],[2,[6,"usuarios-responsaveis"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/negociacoes/usuarios-responsaveis(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_negotiations_available_responsibles_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"negociacoes"],[2,[7,"/"],[2,[6,"usuarios-responsaveis"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/negociacoes/encerrar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_negotiations_close_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"negociacoes"],[2,[7,"/"],[2,[6,"encerrar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/negociacoes/encerrar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_negotiations_close_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"negociacoes"],[2,[7,"/"],[2,[6,"encerrar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/negociacoes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_negotiations_create_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"negociacoes"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/negociacoes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_negotiations_create_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"negociacoes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/negociacoes/excluir(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_negotiations_destroy_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"negociacoes"],[2,[7,"/"],[2,[6,"excluir"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/negociacoes/excluir(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_negotiations_destroy_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"negociacoes"],[2,[7,"/"],[2,[6,"excluir"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/negociacoes/editar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_negotiations_edit_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"negociacoes"],[2,[7,"/"],[2,[6,"editar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/negociacoes/editar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_negotiations_edit_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"negociacoes"],[2,[7,"/"],[2,[6,"editar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/negociacoes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_negotiations_index_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"negociacoes"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/negociacoes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_negotiations_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"negociacoes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/negociacoes/informacoes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_negotiations_negotiation_infos_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"negociacoes"],[2,[7,"/"],[2,[6,"informacoes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/negociacoes/informacoes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_negotiations_negotiation_infos_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"negociacoes"],[2,[7,"/"],[2,[6,"informacoes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/negociacoes/atualizar_responsavel(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_negotiations_update_responsibles_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"negociacoes"],[2,[7,"/"],[2,[6,"atualizar_responsavel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/negociacoes/atualizar_responsavel(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_negotiations_update_responsibles_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"negociacoes"],[2,[7,"/"],[2,[6,"atualizar_responsavel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/negociacoes/atualizar_status(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_negotiations_update_status_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"negociacoes"],[2,[7,"/"],[2,[6,"atualizar_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/negociacoes/atualizar_status(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_negotiations_update_status_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"negociacoes"],[2,[7,"/"],[2,[6,"atualizar_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/negociacoes/atualizar_temperatura(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_negotiations_update_temperature_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"negociacoes"],[2,[7,"/"],[2,[6,"atualizar_temperatura"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/negociacoes/atualizar_temperatura(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_negotiations_update_temperature_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"negociacoes"],[2,[7,"/"],[2,[6,"atualizar_temperatura"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/negociacoes/atualizar_valor(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_negotiations_update_value_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"negociacoes"],[2,[7,"/"],[2,[6,"atualizar_valor"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/negociacoes/atualizar_valor(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_negotiations_update_value_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"negociacoes"],[2,[7,"/"],[2,[6,"atualizar_valor"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /oportunidade/nova(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_opportunities_create_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oportunidade"],[2,[7,"/"],[2,[6,"nova"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /oportunidade/nova(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_opportunities_create_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oportunidade"],[2,[7,"/"],[2,[6,"nova"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/vendas/criar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_purchases_create_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"vendas"],[2,[7,"/"],[2,[6,"criar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/vendas/criar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_purchases_create_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"vendas"],[2,[7,"/"],[2,[6,"criar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/vendas/apagar/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_purchases_destroy_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"vendas"],[2,[7,"/"],[2,[6,"apagar"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/vendas/apagar/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_purchases_destroy_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"vendas"],[2,[7,"/"],[2,[6,"apagar"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/vendas/:purchase_id/gerar-checkout(.:format)
 * @param {any} purchase_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_purchases_generate_checkout_url = __jsr.r({"purchase_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"vendas"],[2,[7,"/"],[2,[3,"purchase_id"],[2,[7,"/"],[2,[6,"gerar-checkout"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/vendas/:purchase_id/gerar-checkout(.:format)
 * @param {any} purchase_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_purchases_generate_checkout_path = __jsr.r({"purchase_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"vendas"],[2,[7,"/"],[2,[3,"purchase_id"],[2,[7,"/"],[2,[6,"gerar-checkout"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/vendas(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_purchases_index_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"vendas"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/vendas(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_purchases_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"vendas"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/vendas/responsaveis(/:purchase_id)(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_purchases_sales_team_and_responsibles_url = __jsr.r({"purchase_id":{},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"vendas"],[2,[7,"/"],[2,[6,"responsaveis"],[2,[1,[2,[7,"/"],[3,"purchase_id"]]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/vendas/responsaveis(/:purchase_id)(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_purchases_sales_team_and_responsibles_path = __jsr.r({"purchase_id":{},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"vendas"],[2,[7,"/"],[2,[6,"responsaveis"],[2,[1,[2,[7,"/"],[3,"purchase_id"]]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/vendas/busca(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_purchases_search_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"vendas"],[2,[7,"/"],[2,[6,"busca"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/vendas/busca(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_purchases_search_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"vendas"],[2,[7,"/"],[2,[6,"busca"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/vendas/:purchase_id(.:format)
 * @param {any} purchase_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_purchases_show_url = __jsr.r({"purchase_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"vendas"],[2,[7,"/"],[2,[3,"purchase_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/vendas/:purchase_id(.:format)
 * @param {any} purchase_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_purchases_show_path = __jsr.r({"purchase_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"vendas"],[2,[7,"/"],[2,[3,"purchase_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/vendas/sincronizar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_purchases_sync_google_sheet_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"vendas"],[2,[7,"/"],[2,[6,"sincronizar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/vendas/sincronizar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_purchases_sync_google_sheet_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"vendas"],[2,[7,"/"],[2,[6,"sincronizar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/vendas/atualizar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_purchases_update_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"vendas"],[2,[7,"/"],[2,[6,"atualizar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/vendas/atualizar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_purchases_update_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"vendas"],[2,[7,"/"],[2,[6,"atualizar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/reports-cliente(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_reports_create_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"reports-cliente"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/reports-cliente(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_reports_create_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"reports-cliente"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/reports-cliente/:customer_id(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_reports_destroy_url = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"reports-cliente"],[2,[7,"/"],[2,[3,"customer_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/reports-cliente/:customer_id(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_reports_destroy_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"reports-cliente"],[2,[7,"/"],[2,[3,"customer_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /cliente/checar_atributo(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_check_crm_attribute_true_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"cliente"],[2,[7,"/"],[2,[6,"checar_atributo"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /cliente/checar_atributo(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_check_crm_attribute_true_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"cliente"],[2,[7,"/"],[2,[6,"checar_atributo"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/clientes/criar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_create_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"clientes"],[2,[7,"/"],[2,[6,"criar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/clientes/criar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_create_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"clientes"],[2,[7,"/"],[2,[6,"criar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/clientes/criar_atividade(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_create_activity_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"clientes"],[2,[7,"/"],[2,[6,"criar_atividade"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/clientes/criar_atividade(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_create_activity_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"clientes"],[2,[7,"/"],[2,[6,"criar_atividade"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/clientes/excluir/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_destroy_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"clientes"],[2,[7,"/"],[2,[6,"excluir"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/clientes/excluir/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_destroy_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"clientes"],[2,[7,"/"],[2,[6,"excluir"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/clientes/exportar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_export_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"clientes"],[2,[7,"/"],[2,[6,"exportar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/clientes/exportar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_export_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"clientes"],[2,[7,"/"],[2,[6,"exportar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/clientes/:id/historico(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_history_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"clientes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"historico"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/clientes/:id/historico(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_history_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"clientes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"historico"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_index_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/clientes/:id(/:name)(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_leads_url = __jsr.r({"id":{"r":true},"name":{},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"clientes"],[2,[7,"/"],[2,[3,"id"],[2,[1,[2,[7,"/"],[3,"name"]]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/clientes/:id(/:name)(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_leads_path = __jsr.r({"id":{"r":true},"name":{},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"clientes"],[2,[7,"/"],[2,[3,"id"],[2,[1,[2,[7,"/"],[3,"name"]]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/equipe_vendas(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_list_sales_team_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"equipe_vendas"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/equipe_vendas(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_list_sales_team_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"equipe_vendas"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/clientes/:id/infos-modal(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_modal_info_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"clientes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"infos-modal"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/clientes/:id/infos-modal(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_modal_info_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"clientes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"infos-modal"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/clientes/reportar-perda(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_report_loss_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"clientes"],[2,[7,"/"],[2,[6,"reportar-perda"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/clientes/reportar-perda(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_report_loss_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"clientes"],[2,[7,"/"],[2,[6,"reportar-perda"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/clientes/reportar-perda-revenda(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_report_repurchase_loss_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"clientes"],[2,[7,"/"],[2,[6,"reportar-perda-revenda"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/clientes/reportar-perda-revenda(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_report_repurchase_loss_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"clientes"],[2,[7,"/"],[2,[6,"reportar-perda-revenda"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/clientes/infos_resumidas(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_resumed_infos_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"clientes"],[2,[7,"/"],[2,[6,"infos_resumidas"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/clientes/infos_resumidas(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_resumed_infos_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"clientes"],[2,[7,"/"],[2,[6,"infos_resumidas"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/clientes/busca(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_search_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"clientes"],[2,[7,"/"],[2,[6,"busca"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/clientes/busca(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_search_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"clientes"],[2,[7,"/"],[2,[6,"busca"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/clientes/consultar_bigboost(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_search_bigboost_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"clientes"],[2,[7,"/"],[2,[6,"consultar_bigboost"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/clientes/consultar_bigboost(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_search_bigboost_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"clientes"],[2,[7,"/"],[2,[6,"consultar_bigboost"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/clientes/pagina/:id(/:name)(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_show_url = __jsr.r({"id":{"r":true},"name":{},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"clientes"],[2,[7,"/"],[2,[6,"pagina"],[2,[7,"/"],[2,[3,"id"],[2,[1,[2,[7,"/"],[3,"name"]]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/clientes/pagina/:id(/:name)(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_show_path = __jsr.r({"id":{"r":true},"name":{},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"clientes"],[2,[7,"/"],[2,[6,"pagina"],[2,[7,"/"],[2,[3,"id"],[2,[1,[2,[7,"/"],[3,"name"]]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /clientes/programa-indicacao/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_show_referral_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clientes"],[2,[7,"/"],[2,[6,"programa-indicacao"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /clientes/programa-indicacao/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_show_referral_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clientes"],[2,[7,"/"],[2,[6,"programa-indicacao"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/clientes/favoritar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_star_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"clientes"],[2,[7,"/"],[2,[6,"favoritar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/clientes/favoritar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_star_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"clientes"],[2,[7,"/"],[2,[6,"favoritar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/clientes/resumo/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_summary_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"clientes"],[2,[7,"/"],[2,[6,"resumo"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/clientes/resumo/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_summary_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"clientes"],[2,[7,"/"],[2,[6,"resumo"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/sincronizar_mixpanel(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_sync_mixpanel_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"sincronizar_mixpanel"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/sincronizar_mixpanel(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_sync_mixpanel_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"sincronizar_mixpanel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/clientes/atualizar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_update_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"clientes"],[2,[7,"/"],[2,[6,"atualizar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/clientes/atualizar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_update_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"clientes"],[2,[7,"/"],[2,[6,"atualizar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/clientes/atualizar-status-revenda(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_update_repurchase_status_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"clientes"],[2,[7,"/"],[2,[6,"atualizar-status-revenda"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/clientes/atualizar-status-revenda(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_update_repurchase_status_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"clientes"],[2,[7,"/"],[2,[6,"atualizar-status-revenda"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/clientes/atualizar-responsavel(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_update_responsible_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"clientes"],[2,[7,"/"],[2,[6,"atualizar-responsavel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/clientes/atualizar-responsavel(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_update_responsible_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"clientes"],[2,[7,"/"],[2,[6,"atualizar-responsavel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/clientes/atualizar-resumo(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_update_resume_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"clientes"],[2,[7,"/"],[2,[6,"atualizar-resumo"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/clientes/atualizar-resumo(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_update_resume_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"clientes"],[2,[7,"/"],[2,[6,"atualizar-resumo"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/clientes/atualizar-status(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_update_status_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"clientes"],[2,[7,"/"],[2,[6,"atualizar-status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/clientes/atualizar-status(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_update_status_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"clientes"],[2,[7,"/"],[2,[6,"atualizar-status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/equipe-vendas/contato-whatsapp/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_whatsapp_contact_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"equipe-vendas"],[2,[7,"/"],[2,[6,"contato-whatsapp"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/equipe-vendas/contato-whatsapp/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_whatsapp_contact_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"equipe-vendas"],[2,[7,"/"],[2,[6,"contato-whatsapp"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/marketing/delay-modal(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delay_modal_index_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"marketing"],[2,[7,"/"],[2,[6,"delay-modal"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/marketing/delay-modal(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delay_modal_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"marketing"],[2,[7,"/"],[2,[6,"delay-modal"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/marketing/delay-modal(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delay_modal_update_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"marketing"],[2,[7,"/"],[2,[6,"delay-modal"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/marketing/delay-modal(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delay_modal_update_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"marketing"],[2,[7,"/"],[2,[6,"delay-modal"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/apagar_artigo_blog/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delete_article_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"apagar_artigo_blog"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/apagar_artigo_blog/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delete_article_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"apagar_artigo_blog"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /destroy_bank_account/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_bank_account_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"destroy_bank_account"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /destroy_bank_account/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_bank_account_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"destroy_bank_account"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /pa/campanhas_home_page/destroy/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_home_campaign_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"campanhas_home_page"],[2,[7,"/"],[2,[6,"destroy"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/campanhas_home_page/destroy/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_home_campaign_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"campanhas_home_page"],[2,[7,"/"],[2,[6,"destroy"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/aovivo/:link(.:format)
 * @param {any} link
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_livestream_url = __jsr.r({"link":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"aovivo"],[2,[7,"/"],[2,[3,"link"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/aovivo/:link(.:format)
 * @param {any} link
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_livestream_path = __jsr.r({"link":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"aovivo"],[2,[7,"/"],[2,[3,"link"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /destroy_notification_subscription(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_notification_subscription_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"destroy_notification_subscription"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /destroy_notification_subscription(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_notification_subscription_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"destroy_notification_subscription"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /users/sign_out(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_user_session_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_out"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /users/sign_out(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_out"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /10-eao(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const eao_event_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"10-eao"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /10-eao(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const eao_event_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"10-eao"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /pa/editar_artigo_blog(/:url_friendly)(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_article_url = __jsr.r({"url_friendly":{},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"editar_artigo_blog"],[2,[1,[2,[7,"/"],[3,"url_friendly"]]],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/editar_artigo_blog(/:url_friendly)(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_article_path = __jsr.r({"url_friendly":{},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"editar_artigo_blog"],[2,[1,[2,[7,"/"],[3,"url_friendly"]]],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * /pa/editar_autores(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_article_authors_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"editar_autores"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /pa/editar_autores(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_article_authors_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"editar_autores"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /pa/editar_destaques(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_featured_article_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"editar_destaques"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /pa/editar_destaques(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_featured_article_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"editar_destaques"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /pa/aovivo/editar(/:id)(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_livestream_url = __jsr.r({"id":{},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"aovivo"],[2,[7,"/"],[2,[6,"editar"],[2,[1,[2,[7,"/"],[3,"id"]]],[1,[2,[8,"."],[3,"format"]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/aovivo/editar(/:id)(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_livestream_path = __jsr.r({"id":{},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"aovivo"],[2,[7,"/"],[2,[6,"editar"],[2,[1,[2,[7,"/"],[3,"id"]]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_rails_conductor_inbound_email_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_rails_conductor_inbound_email_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/password/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_user_password_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /users/password/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /confirmacao_email(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const email_confirmation_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"confirmacao_email"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /confirmacao_email(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const email_confirmation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"confirmacao_email"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /erural-pay/purchase/cancel/:purchase_id(.:format)
 * @param {any} purchase_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const erural_pay_cancel_eruralpay_purchase_url = __jsr.r({"purchase_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"erural-pay"],[2,[7,"/"],[2,[6,"purchase"],[2,[7,"/"],[2,[6,"cancel"],[2,[7,"/"],[2,[3,"purchase_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /erural-pay/purchase/cancel/:purchase_id(.:format)
 * @param {any} purchase_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const erural_pay_cancel_eruralpay_purchase_path = __jsr.r({"purchase_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"erural-pay"],[2,[7,"/"],[2,[6,"purchase"],[2,[7,"/"],[2,[6,"cancel"],[2,[7,"/"],[2,[3,"purchase_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /erural-pay/checkout/:token/auth(.:format)
 * @param {any} token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const erural_pay_checkout_authenticate_customer_url = __jsr.r({"token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"erural-pay"],[2,[7,"/"],[2,[6,"checkout"],[2,[7,"/"],[2,[3,"token"],[2,[7,"/"],[2,[6,"auth"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /erural-pay/checkout/:token/auth(.:format)
 * @param {any} token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const erural_pay_checkout_authenticate_customer_path = __jsr.r({"token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"erural-pay"],[2,[7,"/"],[2,[6,"checkout"],[2,[7,"/"],[2,[3,"token"],[2,[7,"/"],[2,[6,"auth"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /erural-pay/checkout/:token(.:format)
 * @param {any} token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const erural_pay_checkout_validate_token_url = __jsr.r({"token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"erural-pay"],[2,[7,"/"],[2,[6,"checkout"],[2,[7,"/"],[2,[3,"token"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /erural-pay/checkout/:token(.:format)
 * @param {any} token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const erural_pay_checkout_validate_token_path = __jsr.r({"token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"erural-pay"],[2,[7,"/"],[2,[6,"checkout"],[2,[7,"/"],[2,[3,"token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /erural-pay/event/link(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const erural_pay_create_eruralpay_event_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"erural-pay"],[2,[7,"/"],[2,[6,"event"],[2,[7,"/"],[2,[6,"link"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /erural-pay/event/link(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const erural_pay_create_eruralpay_event_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"erural-pay"],[2,[7,"/"],[2,[6,"event"],[2,[7,"/"],[2,[6,"link"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /erural-pay/customer-infos/:customer_id(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const erural_pay_customer_infos_url = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"erural-pay"],[2,[7,"/"],[2,[6,"customer-infos"],[2,[7,"/"],[2,[3,"customer_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /erural-pay/customer-infos/:customer_id(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const erural_pay_customer_infos_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"erural-pay"],[2,[7,"/"],[2,[6,"customer-infos"],[2,[7,"/"],[2,[3,"customer_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /erural-pay/event/link/:eruralpay_id(.:format)
 * @param {any} eruralpay_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const erural_pay_delete_eruralpay_event_url = __jsr.r({"eruralpay_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"erural-pay"],[2,[7,"/"],[2,[6,"event"],[2,[7,"/"],[2,[6,"link"],[2,[7,"/"],[2,[3,"eruralpay_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /erural-pay/event/link/:eruralpay_id(.:format)
 * @param {any} eruralpay_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const erural_pay_delete_eruralpay_event_path = __jsr.r({"eruralpay_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"erural-pay"],[2,[7,"/"],[2,[6,"event"],[2,[7,"/"],[2,[6,"link"],[2,[7,"/"],[2,[3,"eruralpay_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /erural-pay/provider/link/:provider_id(.:format)
 * @param {any} provider_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const erural_pay_delete_provider_eruralpay_id_url = __jsr.r({"provider_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"erural-pay"],[2,[7,"/"],[2,[6,"provider"],[2,[7,"/"],[2,[6,"link"],[2,[7,"/"],[2,[3,"provider_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /erural-pay/provider/link/:provider_id(.:format)
 * @param {any} provider_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const erural_pay_delete_provider_eruralpay_id_path = __jsr.r({"provider_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"erural-pay"],[2,[7,"/"],[2,[6,"provider"],[2,[7,"/"],[2,[6,"link"],[2,[7,"/"],[2,[3,"provider_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /erural-pay/purchase/link/:purchase_id(.:format)
 * @param {any} purchase_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const erural_pay_delete_purchase_eruralpay_id_url = __jsr.r({"purchase_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"erural-pay"],[2,[7,"/"],[2,[6,"purchase"],[2,[7,"/"],[2,[6,"link"],[2,[7,"/"],[2,[3,"purchase_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /erural-pay/purchase/link/:purchase_id(.:format)
 * @param {any} purchase_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const erural_pay_delete_purchase_eruralpay_id_path = __jsr.r({"purchase_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"erural-pay"],[2,[7,"/"],[2,[6,"purchase"],[2,[7,"/"],[2,[6,"link"],[2,[7,"/"],[2,[3,"purchase_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /erural-pay/event-batches-infos/:event_id(.:format)
 * @param {any} event_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const erural_pay_event_batches_info_url = __jsr.r({"event_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"erural-pay"],[2,[7,"/"],[2,[6,"event-batches-infos"],[2,[7,"/"],[2,[3,"event_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /erural-pay/event-batches-infos/:event_id(.:format)
 * @param {any} event_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const erural_pay_event_batches_info_path = __jsr.r({"event_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"erural-pay"],[2,[7,"/"],[2,[6,"event-batches-infos"],[2,[7,"/"],[2,[3,"event_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /erural-pay/event-infos/:event_id(.:format)
 * @param {any} event_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const erural_pay_event_infos_url = __jsr.r({"event_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"erural-pay"],[2,[7,"/"],[2,[6,"event-infos"],[2,[7,"/"],[2,[3,"event_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /erural-pay/event-infos/:event_id(.:format)
 * @param {any} event_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const erural_pay_event_infos_path = __jsr.r({"event_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"erural-pay"],[2,[7,"/"],[2,[6,"event-infos"],[2,[7,"/"],[2,[3,"event_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /erural-pay/provider-infos/:provider_id(.:format)
 * @param {any} provider_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const erural_pay_provider_infos_url = __jsr.r({"provider_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"erural-pay"],[2,[7,"/"],[2,[6,"provider-infos"],[2,[7,"/"],[2,[3,"provider_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /erural-pay/provider-infos/:provider_id(.:format)
 * @param {any} provider_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const erural_pay_provider_infos_path = __jsr.r({"provider_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"erural-pay"],[2,[7,"/"],[2,[6,"provider-infos"],[2,[7,"/"],[2,[3,"provider_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /erural-pay/purchase-infos/:purchase_id(.:format)
 * @param {any} purchase_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const erural_pay_purchase_infos_url = __jsr.r({"purchase_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"erural-pay"],[2,[7,"/"],[2,[6,"purchase-infos"],[2,[7,"/"],[2,[3,"purchase_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /erural-pay/purchase-infos/:purchase_id(.:format)
 * @param {any} purchase_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const erural_pay_purchase_infos_path = __jsr.r({"purchase_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"erural-pay"],[2,[7,"/"],[2,[6,"purchase-infos"],[2,[7,"/"],[2,[3,"purchase_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /erural-pay/provider/link/:provider_id(.:format)
 * @param {any} provider_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const erural_pay_set_provider_eruralpay_id_url = __jsr.r({"provider_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"erural-pay"],[2,[7,"/"],[2,[6,"provider"],[2,[7,"/"],[2,[6,"link"],[2,[7,"/"],[2,[3,"provider_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /erural-pay/provider/link/:provider_id(.:format)
 * @param {any} provider_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const erural_pay_set_provider_eruralpay_id_path = __jsr.r({"provider_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"erural-pay"],[2,[7,"/"],[2,[6,"provider"],[2,[7,"/"],[2,[6,"link"],[2,[7,"/"],[2,[3,"provider_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /erural-pay/purchase/link/:purchase_id(.:format)
 * @param {any} purchase_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const erural_pay_set_purchase_eruralpay_id_url = __jsr.r({"purchase_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"erural-pay"],[2,[7,"/"],[2,[6,"purchase"],[2,[7,"/"],[2,[6,"link"],[2,[7,"/"],[2,[3,"purchase_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /erural-pay/purchase/link/:purchase_id(.:format)
 * @param {any} purchase_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const erural_pay_set_purchase_eruralpay_id_path = __jsr.r({"purchase_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"erural-pay"],[2,[7,"/"],[2,[6,"purchase"],[2,[7,"/"],[2,[6,"link"],[2,[7,"/"],[2,[3,"purchase_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /portal(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const erural_portal_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"portal"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /portal(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const erural_portal_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"portal"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /pa/crm/colunas-funil/:id/adicionar-pessoa(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funnel_columns_add_customer_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"colunas-funil"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"adicionar-pessoa"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/colunas-funil/:id/adicionar-pessoa(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funnel_columns_add_customer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"colunas-funil"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"adicionar-pessoa"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/colunas-funil/:id/adicionar-lista(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funnel_columns_add_list_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"colunas-funil"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"adicionar-lista"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/colunas-funil/:id/adicionar-lista(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funnel_columns_add_list_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"colunas-funil"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"adicionar-lista"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/colunas-funil(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funnel_columns_create_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"colunas-funil"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/colunas-funil(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funnel_columns_create_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"colunas-funil"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/colunas-funil/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funnel_columns_destroy_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"colunas-funil"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/colunas-funil/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funnel_columns_destroy_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"colunas-funil"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/colunas-funil/:id/adicionar-csv(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funnel_columns_import_from_csv_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"colunas-funil"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"adicionar-csv"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/colunas-funil/:id/adicionar-csv(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funnel_columns_import_from_csv_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"colunas-funil"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"adicionar-csv"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/colunas-funil/:column_id/mover-pessoa(.:format)
 * @param {any} column_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funnel_columns_move_customer_url = __jsr.r({"column_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"colunas-funil"],[2,[7,"/"],[2,[3,"column_id"],[2,[7,"/"],[2,[6,"mover-pessoa"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/colunas-funil/:column_id/mover-pessoa(.:format)
 * @param {any} column_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funnel_columns_move_customer_path = __jsr.r({"column_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"colunas-funil"],[2,[7,"/"],[2,[3,"column_id"],[2,[7,"/"],[2,[6,"mover-pessoa"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/colunas-funil/:column_id/remover-pessoa/:customer_id(.:format)
 * @param {any} column_id
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funnel_columns_remove_customer_url = __jsr.r({"column_id":{"r":true},"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"colunas-funil"],[2,[7,"/"],[2,[3,"column_id"],[2,[7,"/"],[2,[6,"remover-pessoa"],[2,[7,"/"],[2,[3,"customer_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/colunas-funil/:column_id/remover-pessoa/:customer_id(.:format)
 * @param {any} column_id
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funnel_columns_remove_customer_path = __jsr.r({"column_id":{"r":true},"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"colunas-funil"],[2,[7,"/"],[2,[3,"column_id"],[2,[7,"/"],[2,[6,"remover-pessoa"],[2,[7,"/"],[2,[3,"customer_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/colunas-funil/:column_id/remover-lista/:list_id(.:format)
 * @param {any} column_id
 * @param {any} list_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funnel_columns_remove_list_url = __jsr.r({"column_id":{"r":true},"list_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"colunas-funil"],[2,[7,"/"],[2,[3,"column_id"],[2,[7,"/"],[2,[6,"remover-lista"],[2,[7,"/"],[2,[3,"list_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/colunas-funil/:column_id/remover-lista/:list_id(.:format)
 * @param {any} column_id
 * @param {any} list_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funnel_columns_remove_list_path = __jsr.r({"column_id":{"r":true},"list_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"colunas-funil"],[2,[7,"/"],[2,[3,"column_id"],[2,[7,"/"],[2,[6,"remover-lista"],[2,[7,"/"],[2,[3,"list_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/colunas-funil/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funnel_columns_update_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"colunas-funil"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/colunas-funil/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funnel_columns_update_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"colunas-funil"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/funis(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funnels_create_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"funis"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/funis(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funnels_create_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"funis"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/funis/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funnels_destroy_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"funis"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/funis/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funnels_destroy_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"funis"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/funis/:id/duplicar(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funnels_duplicate_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"funis"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"duplicar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/funis/:id/duplicar(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funnels_duplicate_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"funis"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"duplicar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/funis(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funnels_index_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"funis"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/funis(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funnels_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"funis"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/funis/:id/reordenar-colunas(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funnels_reorder_columns_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"funis"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"reordenar-colunas"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/funis/:id/reordenar-colunas(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funnels_reorder_columns_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"funis"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"reordenar-colunas"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/funis/busca(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funnels_search_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"funis"],[2,[7,"/"],[2,[6,"busca"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/funis/busca(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funnels_search_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"funis"],[2,[7,"/"],[2,[6,"busca"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/funis/sincronizar-funil(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funnels_search_and_sync_customers_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"funis"],[2,[7,"/"],[2,[6,"sincronizar-funil"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/funis/sincronizar-funil(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funnels_search_and_sync_customers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"funis"],[2,[7,"/"],[2,[6,"sincronizar-funil"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/funis/clientes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funnels_search_customers_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"funis"],[2,[7,"/"],[2,[6,"clientes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/funis/clientes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funnels_search_customers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"funis"],[2,[7,"/"],[2,[6,"clientes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/funis/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funnels_show_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"funis"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/funis/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funnels_show_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"funis"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/funis/:id/favoritar(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funnels_toggle_favorite_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"funis"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"favoritar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/funis/:id/favoritar(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funnels_toggle_favorite_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"funis"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"favoritar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/funis/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funnels_update_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"funis"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/funis/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funnels_update_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"funis"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /auctions/generate_auction_report(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const generate_auction_report_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"auctions"],[2,[7,"/"],[2,[6,"generate_auction_report"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /auctions/generate_auction_report(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const generate_auction_report_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"auctions"],[2,[7,"/"],[2,[6,"generate_auction_report"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /graphql(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const graphql_execute_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"graphql"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /graphql(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const graphql_execute_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"graphql"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /heyflow/create(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const heyflow_answers_create_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"heyflow"],[2,[7,"/"],[2,[6,"create"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /heyflow/create(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const heyflow_answers_create_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"heyflow"],[2,[7,"/"],[2,[6,"create"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /pa/marketing/banners-home/criar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const home_banners_create_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"marketing"],[2,[7,"/"],[2,[6,"banners-home"],[2,[7,"/"],[2,[6,"criar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/marketing/banners-home/criar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const home_banners_create_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"marketing"],[2,[7,"/"],[2,[6,"banners-home"],[2,[7,"/"],[2,[6,"criar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/marketing/banners-home/apagar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const home_banners_destroy_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"marketing"],[2,[7,"/"],[2,[6,"banners-home"],[2,[7,"/"],[2,[6,"apagar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/marketing/banners-home/apagar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const home_banners_destroy_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"marketing"],[2,[7,"/"],[2,[6,"banners-home"],[2,[7,"/"],[2,[6,"apagar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/marketing/banners-home(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const home_banners_index_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"marketing"],[2,[7,"/"],[2,[6,"banners-home"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/marketing/banners-home(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const home_banners_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"marketing"],[2,[7,"/"],[2,[6,"banners-home"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /hubspot/call-webhook(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const hubspot_call_webhook_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"hubspot"],[2,[7,"/"],[2,[6,"call-webhook"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /hubspot/call-webhook(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const hubspot_call_webhook_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"hubspot"],[2,[7,"/"],[2,[6,"call-webhook"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /touro-ideal(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ideal_bull_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"touro-ideal"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /touro-ideal(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ideal_bull_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"touro-ideal"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /touro-ideal/random_responsible(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ideal_bull_random_responsible_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"touro-ideal"],[2,[7,"/"],[2,[6,"random_responsible"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /touro-ideal/random_responsible(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ideal_bull_random_responsible_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"touro-ideal"],[2,[7,"/"],[2,[6,"random_responsible"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /touro-ideal(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ideal_bull_suggestions_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"touro-ideal"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /touro-ideal(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ideal_bull_suggestions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"touro-ideal"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /import_tracked_user(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const import_tracked_user_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"import_tracked_user"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /import_tracked_user(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const import_tracked_user_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"import_tracked_user"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /pa/contas_bancarias(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const index_bank_accounts_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"contas_bancarias"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /pa/contas_bancarias(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const index_bank_accounts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"contas_bancarias"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /pa/campanhas_home_page(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const index_home_campaign_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"campanhas_home_page"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /pa/campanhas_home_page(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const index_home_campaign_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"campanhas_home_page"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /pa/aovivo(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const index_livestream_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"aovivo"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /pa/aovivo(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const index_livestream_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"aovivo"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /pa(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const index_pa_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /pa(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const index_pa_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /conversoes/campanhas(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const landing_page_conversions_campaigns_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"conversoes"],[2,[7,"/"],[2,[6,"campanhas"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /conversoes/campanhas(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const landing_page_conversions_campaigns_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"conversoes"],[2,[7,"/"],[2,[6,"campanhas"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /pa/cursos/aulas(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const lectures_create_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"cursos"],[2,[7,"/"],[2,[6,"aulas"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/cursos/aulas(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const lectures_create_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"cursos"],[2,[7,"/"],[2,[6,"aulas"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/cursos/aulas/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const lectures_destroy_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"cursos"],[2,[7,"/"],[2,[6,"aulas"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/cursos/aulas/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const lectures_destroy_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"cursos"],[2,[7,"/"],[2,[6,"aulas"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/cursos/alternar_ponibilidade/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const lectures_toggle_enable_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"cursos"],[2,[7,"/"],[2,[6,"alternar_ponibilidade"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/cursos/alternar_ponibilidade/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const lectures_toggle_enable_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"cursos"],[2,[7,"/"],[2,[6,"alternar_ponibilidade"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/cursos/aulas(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const lectures_update_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"cursos"],[2,[7,"/"],[2,[6,"aulas"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/cursos/aulas(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const lectures_update_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"cursos"],[2,[7,"/"],[2,[6,"aulas"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /blog/like_article(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const like_blog_article_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"blog"],[2,[7,"/"],[2,[6,"like_article"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /blog/like_article(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const like_blog_article_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"blog"],[2,[7,"/"],[2,[6,"like_article"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /conversoes_landing_page(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const lp_conversions_create_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"conversoes_landing_page"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /conversoes_landing_page(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const lp_conversions_create_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"conversoes_landing_page"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /conversoes/wix(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const lp_conversions_create_wix_conversion_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"conversoes"],[2,[7,"/"],[2,[6,"wix"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /conversoes/wix(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const lp_conversions_create_wix_conversion_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"conversoes"],[2,[7,"/"],[2,[6,"wix"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /download_brazilcomz_pdf(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const lp_conversions_download_brasilcomz_ebook_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"download_brazilcomz_pdf"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /download_brazilcomz_pdf(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const lp_conversions_download_brasilcomz_ebook_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"download_brazilcomz_pdf"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /meetime-webhook(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const meetime_webhook_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"meetime-webhook"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /meetime-webhook(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const meetime_webhook_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"meetime-webhook"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /users/minhas_fazendas(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const my_farms_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"minhas_fazendas"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /users/minhas_fazendas(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const my_farms_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"minhas_fazendas"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/mensagens(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const my_messages_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"mensagens"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /users/mensagens(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const my_messages_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"mensagens"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/historico-de-compras(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const my_shoppings_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"historico-de-compras"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /users/historico-de-compras(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const my_shoppings_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"historico-de-compras"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /pa/novo_artigo_blog(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_article_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"novo_artigo_blog"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /pa/novo_artigo_blog(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_article_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"novo_artigo_blog"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_rails_conductor_inbound_email_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_rails_conductor_inbound_email_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/sources/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_rails_conductor_inbound_email_source_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"sources"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/sources/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_rails_conductor_inbound_email_source_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"sources"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/usuarios/gerar_link(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_tracked_user_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"usuarios"],[2,[7,"/"],[2,[6,"gerar_link"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/usuarios/gerar_link(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_tracked_user_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"usuarios"],[2,[7,"/"],[2,[6,"gerar_link"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/password/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_password_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /users/password/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/sign_up(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_registration_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_up"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /users/sign_up(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_up"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_session_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /users/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /omniauth/facebook(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const omniauth_facebook_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"omniauth"],[2,[7,"/"],[2,[6,"facebook"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /omniauth/facebook(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const omniauth_facebook_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"omniauth"],[2,[7,"/"],[2,[6,"facebook"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /pagarme_bank_account_callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pagarme_bank_account_callback_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pagarme_bank_account_callback"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /pagarme_bank_account_callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pagarme_bank_account_callback_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pagarme_bank_account_callback"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /404(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const page_not_found_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"404"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /404(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const page_not_found_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"404"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /401(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const page_unauthorized_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"401"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /401(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const page_unauthorized_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"401"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /playbook(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const playbooks_index_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"playbook"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /playbook(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const playbooks_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"playbook"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /lotes/pre-lance(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pre_bids_create_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"lotes"],[2,[7,"/"],[2,[6,"pre-lance"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /lotes/pre-lance(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pre_bids_create_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"lotes"],[2,[7,"/"],[2,[6,"pre-lance"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /politica_privacidade(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const privacy_policies_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"politica_privacidade"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /politica_privacidade(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const privacy_policies_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"politica_privacidade"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /pa/selecoes/lotes-do-evento/:customer_id/:auction_id(.:format)
 * @param {any} customer_id
 * @param {any} auction_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const product_selections_available_batches_url = __jsr.r({"customer_id":{"r":true},"auction_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"selecoes"],[2,[7,"/"],[2,[6,"lotes-do-evento"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[3,"auction_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/selecoes/lotes-do-evento/:customer_id/:auction_id(.:format)
 * @param {any} customer_id
 * @param {any} auction_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const product_selections_available_batches_path = __jsr.r({"customer_id":{"r":true},"auction_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"selecoes"],[2,[7,"/"],[2,[6,"lotes-do-evento"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[3,"auction_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/selecoes/eventos-disponiveis(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const product_selections_available_events_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"selecoes"],[2,[7,"/"],[2,[6,"eventos-disponiveis"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/selecoes/eventos-disponiveis(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const product_selections_available_events_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"selecoes"],[2,[7,"/"],[2,[6,"eventos-disponiveis"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/selecoes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const product_selections_destroy_all_selected_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"selecoes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/selecoes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const product_selections_destroy_all_selected_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"selecoes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/selecoes/lotes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const product_selections_destroy_selected_batch_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"selecoes"],[2,[7,"/"],[2,[6,"lotes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/selecoes/lotes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const product_selections_destroy_selected_batch_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"selecoes"],[2,[7,"/"],[2,[6,"lotes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/selecoes/renderizar/:customer_id(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const product_selections_show_url = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"selecoes"],[2,[7,"/"],[2,[6,"renderizar"],[2,[7,"/"],[2,[3,"customer_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/selecoes/renderizar/:customer_id(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const product_selections_show_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"selecoes"],[2,[7,"/"],[2,[6,"renderizar"],[2,[7,"/"],[2,[3,"customer_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/selecoes/atualizar-lotes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const product_selections_update_selections_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"selecoes"],[2,[7,"/"],[2,[6,"atualizar-lotes"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/selecoes/atualizar-lotes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const product_selections_update_selections_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"selecoes"],[2,[7,"/"],[2,[6,"atualizar-lotes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/fornecedores(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const providers_create_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"fornecedores"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /pa/fornecedores(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const providers_create_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"fornecedores"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /pa/fornecedores(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const providers_index_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"fornecedores"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /pa/fornecedores(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const providers_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"fornecedores"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /pa/fornecedores/infos/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const providers_infos_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"fornecedores"],[2,[7,"/"],[2,[6,"infos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/fornecedores/infos/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const providers_infos_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"fornecedores"],[2,[7,"/"],[2,[6,"infos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/fornecedores/select(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const providers_select_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"fornecedores"],[2,[7,"/"],[2,[6,"select"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/fornecedores/select(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const providers_select_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"fornecedores"],[2,[7,"/"],[2,[6,"select"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/fornecedores/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const providers_show_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"fornecedores"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/fornecedores/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const providers_show_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"fornecedores"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/fornecedores/infos/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const providers_update_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"fornecedores"],[2,[7,"/"],[2,[6,"infos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/fornecedores/infos/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const providers_update_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"fornecedores"],[2,[7,"/"],[2,[6,"infos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /minhas-recomendacoes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const purchase_preferences_index_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"minhas-recomendacoes"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /minhas-recomendacoes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const purchase_preferences_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"minhas-recomendacoes"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /preferencias-de-compra(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const purchase_preferences_preference_choices_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"preferencias-de-compra"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /preferencias-de-compra(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const purchase_preferences_preference_choices_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"preferencias-de-compra"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /perfil-de-compra(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const purchase_preferences_purchase_profiles_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"perfil-de-compra"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /perfil-de-compra(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const purchase_preferences_purchase_profiles_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"perfil-de-compra"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /preferencias-de-compra(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const purchase_preferences_save_purchase_preferences_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"preferencias-de-compra"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /preferencias-de-compra(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const purchase_preferences_save_purchase_preferences_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"preferencias-de-compra"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /perfil-de-compra(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const purchase_preferences_save_purchase_profiles_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"perfil-de-compra"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /perfil-de-compra(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const purchase_preferences_save_purchase_profiles_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"perfil-de-compra"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /asaas-callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const purchase_transaction_payments_asaas_callback_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"asaas-callback"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /asaas-callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const purchase_transaction_payments_asaas_callback_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"asaas-callback"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /pa/cobrancas(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const purchase_transaction_payments_create_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"cobrancas"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /pa/cobrancas(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const purchase_transaction_payments_create_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"cobrancas"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /pa/cobrancas/:asaas_payment_id(.:format)
 * @param {any} asaas_payment_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const purchase_transaction_payments_destroy_url = __jsr.r({"asaas_payment_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"cobrancas"],[2,[7,"/"],[2,[3,"asaas_payment_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/cobrancas/:asaas_payment_id(.:format)
 * @param {any} asaas_payment_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const purchase_transaction_payments_destroy_path = __jsr.r({"asaas_payment_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"cobrancas"],[2,[7,"/"],[2,[3,"asaas_payment_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /d4sign-callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const purchase_transactions_d4sign_callback_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"d4sign-callback"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /d4sign-callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const purchase_transactions_d4sign_callback_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"d4sign-callback"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /pa/transacoes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const purchase_transactions_destroy_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"transacoes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/transacoes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const purchase_transactions_destroy_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"transacoes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/transacoes/:id/gerar-documento(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const purchase_transactions_generate_document_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"transacoes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"gerar-documento"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/transacoes/:id/gerar-documento(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const purchase_transactions_generate_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"transacoes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"gerar-documento"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/transacoes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const purchase_transactions_index_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"transacoes"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /pa/transacoes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const purchase_transactions_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"transacoes"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /pa/transacoes/infos/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const purchase_transactions_infos_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"transacoes"],[2,[7,"/"],[2,[6,"infos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/transacoes/infos/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const purchase_transactions_infos_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"transacoes"],[2,[7,"/"],[2,[6,"infos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/transacoes/leiloes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const purchase_transactions_present_auctions_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"transacoes"],[2,[7,"/"],[2,[6,"leiloes"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/transacoes/leiloes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const purchase_transactions_present_auctions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"transacoes"],[2,[7,"/"],[2,[6,"leiloes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/transacoes/atualizar-url-download(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const purchase_transactions_refresh_url_download_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"transacoes"],[2,[7,"/"],[2,[6,"atualizar-url-download"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/transacoes/atualizar-url-download(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const purchase_transactions_refresh_url_download_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"transacoes"],[2,[7,"/"],[2,[6,"atualizar-url-download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/transacoes/busca(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const purchase_transactions_search_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"transacoes"],[2,[7,"/"],[2,[6,"busca"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/transacoes/busca(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const purchase_transactions_search_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"transacoes"],[2,[7,"/"],[2,[6,"busca"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/transacoes/enviar-para-assinatura(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const purchase_transactions_send_to_signers_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"transacoes"],[2,[7,"/"],[2,[6,"enviar-para-assinatura"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/transacoes/enviar-para-assinatura(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const purchase_transactions_send_to_signers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"transacoes"],[2,[7,"/"],[2,[6,"enviar-para-assinatura"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/transacoes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const purchase_transactions_show_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"transacoes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/transacoes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const purchase_transactions_show_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"transacoes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/transacoes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const purchase_transactions_update_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"transacoes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/transacoes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const purchase_transactions_update_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"transacoes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/transacoes/:id/atualizar-status(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const purchase_transactions_update_status_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"transacoes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"atualizar-status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/transacoes/:id/atualizar-status(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const purchase_transactions_update_status_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"transacoes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"atualizar-status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /push_notification_track(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const push_notification_track_subscription_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"push_notification_track"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /push_notification_track(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const push_notification_track_subscription_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"push_notification_track"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /pa/marketing/nova_push_notificacao(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const push_pastar_notification_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"marketing"],[2,[7,"/"],[2,[6,"nova_push_notificacao"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/marketing/nova_push_notificacao(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const push_pastar_notification_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"marketing"],[2,[7,"/"],[2,[6,"nova_push_notificacao"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/representations/redirect/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation_url = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/active_storage/representations/redirect/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation_path = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/representations/proxy/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation_proxy_url = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/active_storage/representations/proxy/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation_proxy_path = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format)
 * @param {any} inbound_email_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_reroute_url = __jsr.r({"inbound_email_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[3,"inbound_email_id"],[2,[7,"/"],[2,[6,"reroute"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format)
 * @param {any} inbound_email_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_reroute_path = __jsr.r({"inbound_email_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[3,"inbound_email_id"],[2,[7,"/"],[2,[6,"reroute"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/sources(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_sources_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"sources"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/sources(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_sources_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"sources"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_emails_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/direct_uploads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_direct_uploads_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"direct_uploads"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/active_storage/direct_uploads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_direct_uploads_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"direct_uploads"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_key/*filename(.:format)
 * @param {any} encoded_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_disk_service_url = __jsr.r({"encoded_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_key/*filename(.:format)
 * @param {any} encoded_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_disk_service_path = __jsr.r({"encoded_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mailgun/inbound_emails/mime(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mailgun_inbound_emails_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mailgun"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"mime"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/action_mailbox/mailgun/inbound_emails/mime(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mailgun_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mailgun"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"mime"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mandrill_inbound_emails_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mandrill_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mandrill_inbound_health_check_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mandrill_inbound_health_check_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/postmark/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_postmark_inbound_emails_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"postmark"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/action_mailbox/postmark/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_postmark_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"postmark"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/relay/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_relay_inbound_emails_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"relay"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/action_mailbox/relay/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_relay_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"relay"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/sendgrid/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_sendgrid_inbound_emails_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"sendgrid"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/action_mailbox/sendgrid/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_sendgrid_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"sendgrid"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/redirect/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob_url = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/redirect/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob_path = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/proxy/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob_proxy_url = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/proxy/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob_proxy_path = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rankings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rankings_index_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rankings"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /rankings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rankings_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rankings"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /rankings/top-10-touros-ganho-peso(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rankings_top_bulls_pd_ed_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rankings"],[2,[7,"/"],[2,[6,"top-10-touros-ganho-peso"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /rankings/top-10-touros-ganho-peso(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rankings_top_bulls_pd_ed_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rankings"],[2,[7,"/"],[2,[6,"top-10-touros-ganho-peso"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /rankings/top-10-touros-habilidade-materna(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rankings_top_bulls_pm_em_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rankings"],[2,[7,"/"],[2,[6,"top-10-touros-habilidade-materna"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /rankings/top-10-touros-habilidade-materna(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rankings_top_bulls_pm_em_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rankings"],[2,[7,"/"],[2,[6,"top-10-touros-habilidade-materna"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /rankings/top-10-touros-iabcz(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rankings_top_iabcz_bulls_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rankings"],[2,[7,"/"],[2,[6,"top-10-touros-iabcz"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /rankings/top-10-touros-iabcz(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rankings_top_iabcz_bulls_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rankings"],[2,[7,"/"],[2,[6,"top-10-touros-iabcz"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /rankings/top-10-femeas-iabcz(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rankings_top_iabcz_matrices_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rankings"],[2,[7,"/"],[2,[6,"top-10-femeas-iabcz"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /rankings/top-10-femeas-iabcz(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rankings_top_iabcz_matrices_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rankings"],[2,[7,"/"],[2,[6,"top-10-femeas-iabcz"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /rankings/top-10-femeas-ganho-peso(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rankings_top_matrices_pd_ed_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rankings"],[2,[7,"/"],[2,[6,"top-10-femeas-ganho-peso"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /rankings/top-10-femeas-ganho-peso(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rankings_top_matrices_pd_ed_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rankings"],[2,[7,"/"],[2,[6,"top-10-femeas-ganho-peso"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /rankings/top-10-femeas-habilidade-materna(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rankings_top_matrices_pm_em_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rankings"],[2,[7,"/"],[2,[6,"top-10-femeas-habilidade-materna"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /rankings/top-10-femeas-habilidade-materna(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rankings_top_matrices_pm_em_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rankings"],[2,[7,"/"],[2,[6,"top-10-femeas-habilidade-materna"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /recomendados/notificar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const recommendations_send_notification_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"recomendados"],[2,[7,"/"],[2,[6,"notificar"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /recomendados/notificar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const recommendations_send_notification_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"recomendados"],[2,[7,"/"],[2,[6,"notificar"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /recomendados/:questionary_token(.:format)
 * @param {any} questionary_token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const recommendations_show_url = __jsr.r({"questionary_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"recomendados"],[2,[7,"/"],[2,[3,"questionary_token"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /recomendados/:questionary_token(.:format)
 * @param {any} questionary_token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const recommendations_show_path = __jsr.r({"questionary_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"recomendados"],[2,[7,"/"],[2,[3,"questionary_token"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /reenvio_confirmacao_email(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const resend_email_confirmation_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"reenvio_confirmacao_email"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /reenvio_confirmacao_email(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const resend_email_confirmation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"reenvio_confirmacao_email"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /resque
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const resque_server_url = __jsr.r({}, [2,[7,"/"],[6,"resque"]], true);

/**
 * Generates rails route to
 * /resque
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const resque_server_path = __jsr.r({}, [2,[7,"/"],[6,"resque"]]);

/**
 * Generates rails route to
 * /lotes/lance-para-revisao/:url_friendly(.:format)
 * @param {any} url_friendly
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reviewable_bids_create_url = __jsr.r({"url_friendly":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"lotes"],[2,[7,"/"],[2,[6,"lance-para-revisao"],[2,[7,"/"],[2,[3,"url_friendly"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /lotes/lance-para-revisao/:url_friendly(.:format)
 * @param {any} url_friendly
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reviewable_bids_create_path = __jsr.r({"url_friendly":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"lotes"],[2,[7,"/"],[2,[6,"lance-para-revisao"],[2,[7,"/"],[2,[3,"url_friendly"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const root_url = __jsr.r({}, [7,"/"], true);

/**
 * Generates rails route to
 * /
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const root_path = __jsr.r({}, [7,"/"]);

/**
 * Generates rails route to
 * /search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const search_ad_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const search_ad_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /search/results(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const search_results_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"results"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /search/results(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const search_results_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"results"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /segment/crm/qualify/:user_id(.:format)
 * @param {any} user_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const segment_qualify_lead_url = __jsr.r({"user_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"segment"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"qualify"],[2,[7,"/"],[2,[3,"user_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /segment/crm/qualify/:user_id(.:format)
 * @param {any} user_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const segment_qualify_lead_path = __jsr.r({"user_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"segment"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"qualify"],[2,[7,"/"],[2,[3,"user_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /segment/crm/whatsapp-campaign/:user_id(.:format)
 * @param {any} user_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const segment_send_whatsapp_campaign_url = __jsr.r({"user_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"segment"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"whatsapp-campaign"],[2,[7,"/"],[2,[3,"user_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /segment/crm/whatsapp-campaign/:user_id(.:format)
 * @param {any} user_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const segment_send_whatsapp_campaign_path = __jsr.r({"user_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"segment"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"whatsapp-campaign"],[2,[7,"/"],[2,[3,"user_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /selecoes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const selections_index_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"selecoes"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /selecoes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const selections_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"selecoes"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /selecoes/:selection(.:format)
 * @param {any} selection
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const selections_show_url = __jsr.r({"selection":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"selecoes"],[2,[7,"/"],[2,[3,"selection"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /selecoes/:selection(.:format)
 * @param {any} selection
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const selections_show_path = __jsr.r({"selection":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"selecoes"],[2,[7,"/"],[2,[3,"selection"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /venda_conosco(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sell_with_us_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"venda_conosco"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /venda_conosco(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sell_with_us_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"venda_conosco"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /fale_conosco(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_contact_us_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"fale_conosco"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /fale_conosco(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_contact_us_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"fale_conosco"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /pa/marketing/nova_push_notificacao(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_push_pastar_notification_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"marketing"],[2,[7,"/"],[2,[6,"nova_push_notificacao"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/marketing/nova_push_notificacao(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_push_pastar_notification_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"marketing"],[2,[7,"/"],[2,[6,"nova_push_notificacao"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/serasa(/:year)(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const serasa_scores_index_url = __jsr.r({"year":{},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"serasa"],[2,[1,[2,[7,"/"],[3,"year"]]],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/serasa(/:year)(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const serasa_scores_index_path = __jsr.r({"year":{},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"serasa"],[2,[1,[2,[7,"/"],[3,"year"]]],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * /pa/serasa/consulta(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const serasa_scores_query_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"serasa"],[2,[7,"/"],[2,[6,"consulta"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/serasa/consulta(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const serasa_scores_query_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"serasa"],[2,[7,"/"],[2,[6,"consulta"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/serasa/busca(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const serasa_scores_search_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"serasa"],[2,[7,"/"],[2,[6,"busca"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/serasa/busca(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const serasa_scores_search_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"serasa"],[2,[7,"/"],[2,[6,"busca"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/serasa/consulta/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const serasa_scores_show_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"serasa"],[2,[7,"/"],[2,[6,"consulta"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/serasa/consulta/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const serasa_scores_show_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"serasa"],[2,[7,"/"],[2,[6,"consulta"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /422(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const server_error_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"422"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /422(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const server_error_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"422"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /pa/parceiro/set_tracked_user(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const set_tracked_user_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"parceiro"],[2,[7,"/"],[2,[6,"set_tracked_user"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/parceiro/set_tracked_user(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const set_tracked_user_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"parceiro"],[2,[7,"/"],[2,[6,"set_tracked_user"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /conteudo/:url_friendly(.:format)
 * @param {any} url_friendly
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_article_url = __jsr.r({"url_friendly":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"conteudo"],[2,[7,"/"],[2,[3,"url_friendly"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /conteudo/:url_friendly(.:format)
 * @param {any} url_friendly
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_article_path = __jsr.r({"url_friendly":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"conteudo"],[2,[7,"/"],[2,[3,"url_friendly"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /conteudo(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_blog_home_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"conteudo"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /conteudo(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_blog_home_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"conteudo"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /pa/financeiro(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_financial_content_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"financeiro"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /pa/financeiro(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_financial_content_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"financeiro"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /aovivo(/:url_friendly)(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_live_url = __jsr.r({"url_friendly":{},"format":{}}, [2,[7,"/"],[2,[6,"aovivo"],[2,[1,[2,[7,"/"],[3,"url_friendly"]]],[1,[2,[8,"."],[3,"format"]]]]]], true);

/**
 * Generates rails route to
 * /aovivo(/:url_friendly)(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_live_path = __jsr.r({"url_friendly":{},"format":{}}, [2,[7,"/"],[2,[6,"aovivo"],[2,[1,[2,[7,"/"],[3,"url_friendly"]]],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pa/marketing(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_marketing_content_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"marketing"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /pa/marketing(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_marketing_content_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"marketing"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /parceiro/:token(.:format)
 * @param {any} token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_tracked_user_url = __jsr.r({"token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"parceiro"],[2,[7,"/"],[2,[3,"token"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /parceiro/:token(.:format)
 * @param {any} token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_tracked_user_path = __jsr.r({"token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"parceiro"],[2,[7,"/"],[2,[3,"token"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /pa/usuarios(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_users_content_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"usuarios"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /pa/usuarios(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_users_content_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"usuarios"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /sidekiq
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sidekiq_web_url = __jsr.r({}, [2,[7,"/"],[6,"sidekiq"]], true);

/**
 * Generates rails route to
 * /sidekiq
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sidekiq_web_path = __jsr.r({}, [2,[7,"/"],[6,"sidekiq"]]);

/**
 * Generates rails route to
 * /slack/interacoes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const slack_interactions_interactivity_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"slack"],[2,[7,"/"],[2,[6,"interacoes"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /slack/interacoes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const slack_interactions_interactivity_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"slack"],[2,[7,"/"],[2,[6,"interacoes"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /smartrep/autenticar/:customer_id(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const smartreps_auth_page_url = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"smartrep"],[2,[7,"/"],[2,[6,"autenticar"],[2,[7,"/"],[2,[3,"customer_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /smartrep/autenticar/:customer_id(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const smartreps_auth_page_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"smartrep"],[2,[7,"/"],[2,[6,"autenticar"],[2,[7,"/"],[2,[3,"customer_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /smartrep/autorizar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const smartreps_authorize_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"smartrep"],[2,[7,"/"],[2,[6,"autorizar"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /smartrep/autorizar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const smartreps_authorize_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"smartrep"],[2,[7,"/"],[2,[6,"autorizar"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /smartrep/atualizar-deps/:customer_id(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const smartreps_enqueue_dep_update_url = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"smartrep"],[2,[7,"/"],[2,[6,"atualizar-deps"],[2,[7,"/"],[2,[3,"customer_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /smartrep/atualizar-deps/:customer_id(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const smartreps_enqueue_dep_update_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"smartrep"],[2,[7,"/"],[2,[6,"atualizar-deps"],[2,[7,"/"],[2,[3,"customer_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /smartrep/importar-csv(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const smartreps_import_csv_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"smartrep"],[2,[7,"/"],[2,[6,"importar-csv"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /smartrep/importar-csv(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const smartreps_import_csv_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"smartrep"],[2,[7,"/"],[2,[6,"importar-csv"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /smartrep/:customer_id(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const smartreps_index_url = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"smartrep"],[2,[7,"/"],[2,[3,"customer_id"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /smartrep/:customer_id(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const smartreps_index_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"smartrep"],[2,[7,"/"],[2,[3,"customer_id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /smartrep/nao-autorizado/:customer_id(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const smartreps_unauthorized_url = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"smartrep"],[2,[7,"/"],[2,[6,"nao-autorizado"],[2,[7,"/"],[2,[3,"customer_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /smartrep/nao-autorizado/:customer_id(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const smartreps_unauthorized_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"smartrep"],[2,[7,"/"],[2,[6,"nao-autorizado"],[2,[7,"/"],[2,[3,"customer_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /split
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const split_dashboard_url = __jsr.r({}, [2,[7,"/"],[6,"split"]], true);

/**
 * Generates rails route to
 * /split
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const split_dashboard_path = __jsr.r({}, [2,[7,"/"],[6,"split"]]);

/**
 * Generates rails route to
 * /pa/alternar_status_aovivo/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const toggle_livestream_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"alternar_status_aovivo"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/alternar_status_aovivo/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const toggle_livestream_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"alternar_status_aovivo"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /topcorte/aovivo(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const topcorte_aovivo_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"topcorte"],[2,[7,"/"],[2,[6,"aovivo"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /topcorte/aovivo(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const topcorte_aovivo_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"topcorte"],[2,[7,"/"],[2,[6,"aovivo"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /pagamento/capturar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const transactions_capture_checkout_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pagamento"],[2,[7,"/"],[2,[6,"capturar"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /pagamento/capturar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const transactions_capture_checkout_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pagamento"],[2,[7,"/"],[2,[6,"capturar"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /pagarme_transaction_callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const transactions_pagarme_transaction_callback_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pagarme_transaction_callback"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /pagarme_transaction_callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const transactions_pagarme_transaction_callback_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pagarme_transaction_callback"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /send_purchase_to_facebook(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const transactions_send_purchase_to_facebook_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"send_purchase_to_facebook"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /send_purchase_to_facebook(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const transactions_send_purchase_to_facebook_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"send_purchase_to_facebook"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /pagamento(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const transactions_show_checkout_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pagamento"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /pagamento(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const transactions_show_checkout_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pagamento"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /pa/editar_artigo_blog/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_article_url = __jsr.r({"id":{"r":true},"format":{},"subdomain":{"d":"uploads"}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"editar_artigo_blog"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/editar_artigo_blog/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_article_path = __jsr.r({"id":{"r":true},"format":{},"subdomain":{"d":"uploads"}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"editar_artigo_blog"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/atualizar_dados_autores(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_author_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"atualizar_dados_autores"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /pa/atualizar_dados_autores(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_author_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"atualizar_dados_autores"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /pa/alterar_destaque(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_featured_article_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"alterar_destaque"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /pa/alterar_destaque(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_featured_article_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"alterar_destaque"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /pa/aovivo/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_livestream_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"aovivo"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/aovivo/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_livestream_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"aovivo"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_token(.:format)
 * @param {any} encoded_token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_rails_disk_service_url = __jsr.r({"encoded_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_token(.:format)
 * @param {any} encoded_token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_rails_disk_service_path = __jsr.r({"encoded_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /update_subscription(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_subscription_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"update_subscription"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /update_subscription(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_subscription_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"update_subscription"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /pa/upload_imagem_blog(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const upload_article_image_url = __jsr.r({"format":{},"subdomain":{"d":"uploads"}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"upload_imagem_blog"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /pa/upload_imagem_blog(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const upload_article_image_path = __jsr.r({"format":{},"subdomain":{"d":"uploads"}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"upload_imagem_blog"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /pa/upload_video_blog(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const upload_article_video_url = __jsr.r({"format":{},"subdomain":{"d":"uploads"}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"upload_video_blog"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /pa/upload_video_blog(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const upload_article_video_path = __jsr.r({"format":{},"subdomain":{"d":"uploads"}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"upload_video_blog"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /termos_uso(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const use_terms_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"termos_uso"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /termos_uso(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const use_terms_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"termos_uso"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /pa/tarefas/criar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_assignments_create_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"tarefas"],[2,[7,"/"],[2,[6,"criar"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/tarefas/criar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_assignments_create_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"tarefas"],[2,[7,"/"],[2,[6,"criar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/enviar_documento(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_documents_create_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"enviar_documento"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /users/enviar_documento(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_documents_create_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"enviar_documento"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/mostrar_documento_crm/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_documents_crm_show_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"mostrar_documento_crm"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /users/mostrar_documento_crm/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_documents_crm_show_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"mostrar_documento_crm"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/apagar_documento/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_documents_destroy_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"apagar_documento"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /users/apagar_documento/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_documents_destroy_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"apagar_documento"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/mostrar_documento/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_documents_show_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"mostrar_documento"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /users/mostrar_documento/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_documents_show_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"mostrar_documento"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_edit_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /users/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_edit_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/auth/facebook(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_facebook_omniauth_authorize_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"facebook"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /users/auth/facebook(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_facebook_omniauth_authorize_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"facebook"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/auth/facebook/callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_facebook_omniauth_callback_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"facebook"],[2,[7,"/"],[2,[6,"callback"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /users/auth/facebook/callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_facebook_omniauth_callback_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"facebook"],[2,[7,"/"],[2,[6,"callback"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /user(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_info_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /user(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_info_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /users/password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_password_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /users/password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/perfil(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_profile_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"perfil"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /users/perfil(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_profile_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"perfil"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_registration_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /users/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_session_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /users/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /users/nome(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_name_by_login_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"nome"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /users/nome(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_name_by_login_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"nome"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/hunter/dashboard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_show_dashboard_hunter_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"hunter"],[2,[7,"/"],[2,[6,"dashboard"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /users/hunter/dashboard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_show_dashboard_hunter_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"hunter"],[2,[7,"/"],[2,[6,"dashboard"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /vagas(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const vagas_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"vagas"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /vagas(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const vagas_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"vagas"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /pa/crm/carteiras/:wallet_owner_id/oportunidades/:auction_id(.:format)
 * @param {any} wallet_owner_id
 * @param {any} auction_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const wallet_opportunities_index_url = __jsr.r({"wallet_owner_id":{"r":true},"auction_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"carteiras"],[2,[7,"/"],[2,[3,"wallet_owner_id"],[2,[7,"/"],[2,[6,"oportunidades"],[2,[7,"/"],[2,[3,"auction_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/carteiras/:wallet_owner_id/oportunidades/:auction_id(.:format)
 * @param {any} wallet_owner_id
 * @param {any} auction_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const wallet_opportunities_index_path = __jsr.r({"wallet_owner_id":{"r":true},"auction_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"carteiras"],[2,[7,"/"],[2,[3,"wallet_owner_id"],[2,[7,"/"],[2,[6,"oportunidades"],[2,[7,"/"],[2,[3,"auction_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/carteiras/:wallet_owner_id/oportunidades/:auction_id/buscar-clientes(.:format)
 * @param {any} wallet_owner_id
 * @param {any} auction_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const wallet_opportunities_search_customers_url = __jsr.r({"wallet_owner_id":{"r":true},"auction_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"carteiras"],[2,[7,"/"],[2,[3,"wallet_owner_id"],[2,[7,"/"],[2,[6,"oportunidades"],[2,[7,"/"],[2,[3,"auction_id"],[2,[7,"/"],[2,[6,"buscar-clientes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/carteiras/:wallet_owner_id/oportunidades/:auction_id/buscar-clientes(.:format)
 * @param {any} wallet_owner_id
 * @param {any} auction_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const wallet_opportunities_search_customers_path = __jsr.r({"wallet_owner_id":{"r":true},"auction_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"carteiras"],[2,[7,"/"],[2,[3,"wallet_owner_id"],[2,[7,"/"],[2,[6,"oportunidades"],[2,[7,"/"],[2,[3,"auction_id"],[2,[7,"/"],[2,[6,"buscar-clientes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/oportunidades-de-carteira/atualizar-interesse(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const wallet_opportunities_update_interest_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"oportunidades-de-carteira"],[2,[7,"/"],[2,[6,"atualizar-interesse"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/oportunidades-de-carteira/atualizar-interesse(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const wallet_opportunities_update_interest_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"oportunidades-de-carteira"],[2,[7,"/"],[2,[6,"atualizar-interesse"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/carteiras/:id/home/aniversariantes(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const wallets_birthday_customers_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"carteiras"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"home"],[2,[7,"/"],[2,[6,"aniversariantes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/carteiras/:id/home/aniversariantes(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const wallets_birthday_customers_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"carteiras"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"home"],[2,[7,"/"],[2,[6,"aniversariantes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/carteiras/:id/funil/filtrar-clientes(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const wallets_filter_customers_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"carteiras"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"funil"],[2,[7,"/"],[2,[6,"filtrar-clientes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/carteiras/:id/funil/filtrar-clientes(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const wallets_filter_customers_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"carteiras"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"funil"],[2,[7,"/"],[2,[6,"filtrar-clientes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/carteiras/:id/funil(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const wallets_funnel_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"carteiras"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"funil"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/carteiras/:id/funil(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const wallets_funnel_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"carteiras"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"funil"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/carteiras(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const wallets_index_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"carteiras"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/carteiras(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const wallets_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"carteiras"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/carteiras/:id/oportunidades(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const wallets_opportunities_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"carteiras"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"oportunidades"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/carteiras/:id/oportunidades(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const wallets_opportunities_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"carteiras"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"oportunidades"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/carteiras/:id/home/conversoes(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const wallets_recent_conversions_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"carteiras"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"home"],[2,[7,"/"],[2,[6,"conversoes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/carteiras/:id/home/conversoes(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const wallets_recent_conversions_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"carteiras"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"home"],[2,[7,"/"],[2,[6,"conversoes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/carteiras/:id/home/passagens-de-bastao(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const wallets_recent_customer_assignments_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"carteiras"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"home"],[2,[7,"/"],[2,[6,"passagens-de-bastao"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/carteiras/:id/home/passagens-de-bastao(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const wallets_recent_customer_assignments_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"carteiras"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"home"],[2,[7,"/"],[2,[6,"passagens-de-bastao"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/carteiras/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const wallets_show_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"carteiras"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/carteiras/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const wallets_show_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"carteiras"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/carteiras/:id/filtrar-clientes(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const wallets_wallet_homepage_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"carteiras"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"filtrar-clientes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/carteiras/:id/filtrar-clientes(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const wallets_wallet_homepage_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"carteiras"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"filtrar-clientes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/marketing/campanhas-whatsapp/criar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const whatsapp_campaigns_create_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"marketing"],[2,[7,"/"],[2,[6,"campanhas-whatsapp"],[2,[7,"/"],[2,[6,"criar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/marketing/campanhas-whatsapp/criar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const whatsapp_campaigns_create_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"marketing"],[2,[7,"/"],[2,[6,"campanhas-whatsapp"],[2,[7,"/"],[2,[6,"criar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/marketing/campanhas-whatsapp/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const whatsapp_campaigns_destroy_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"marketing"],[2,[7,"/"],[2,[6,"campanhas-whatsapp"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/marketing/campanhas-whatsapp/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const whatsapp_campaigns_destroy_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"marketing"],[2,[7,"/"],[2,[6,"campanhas-whatsapp"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/marketing/campanhas-whatsapp/:id/duplicar(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const whatsapp_campaigns_duplicate_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"marketing"],[2,[7,"/"],[2,[6,"campanhas-whatsapp"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"duplicar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/marketing/campanhas-whatsapp/:id/duplicar(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const whatsapp_campaigns_duplicate_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"marketing"],[2,[7,"/"],[2,[6,"campanhas-whatsapp"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"duplicar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/marketing/campanhas-whatsapp/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const whatsapp_campaigns_edit_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"marketing"],[2,[7,"/"],[2,[6,"campanhas-whatsapp"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/marketing/campanhas-whatsapp/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const whatsapp_campaigns_edit_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"marketing"],[2,[7,"/"],[2,[6,"campanhas-whatsapp"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/marketing/campanhas-whatsapp(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const whatsapp_campaigns_manage_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"marketing"],[2,[7,"/"],[2,[6,"campanhas-whatsapp"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/marketing/campanhas-whatsapp(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const whatsapp_campaigns_manage_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"marketing"],[2,[7,"/"],[2,[6,"campanhas-whatsapp"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/marketing/campanhas-whatsapp/criar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const whatsapp_campaigns_new_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"marketing"],[2,[7,"/"],[2,[6,"campanhas-whatsapp"],[2,[7,"/"],[2,[6,"criar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/marketing/campanhas-whatsapp/criar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const whatsapp_campaigns_new_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"marketing"],[2,[7,"/"],[2,[6,"campanhas-whatsapp"],[2,[7,"/"],[2,[6,"criar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/marketing/campanhas-whatsapp/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const whatsapp_campaigns_update_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"marketing"],[2,[7,"/"],[2,[6,"campanhas-whatsapp"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/marketing/campanhas-whatsapp/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const whatsapp_campaigns_update_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"marketing"],[2,[7,"/"],[2,[6,"campanhas-whatsapp"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/marketing/whatsapp_links(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const whatsapp_links_create_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"marketing"],[2,[7,"/"],[2,[6,"whatsapp_links"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/marketing/whatsapp_links(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const whatsapp_links_create_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"marketing"],[2,[7,"/"],[2,[6,"whatsapp_links"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/marketing/whatsapp_links/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const whatsapp_links_destroy_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"marketing"],[2,[7,"/"],[2,[6,"whatsapp_links"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/marketing/whatsapp_links/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const whatsapp_links_destroy_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"marketing"],[2,[7,"/"],[2,[6,"whatsapp_links"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/marketing/whatsapp_links/gerenciar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const whatsapp_links_manage_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"marketing"],[2,[7,"/"],[2,[6,"whatsapp_links"],[2,[7,"/"],[2,[6,"gerenciar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/marketing/whatsapp_links/gerenciar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const whatsapp_links_manage_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"marketing"],[2,[7,"/"],[2,[6,"whatsapp_links"],[2,[7,"/"],[2,[6,"gerenciar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /wpp/:url(.:format)
 * @param {any} url
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const whatsapp_links_show_url = __jsr.r({"url":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"wpp"],[2,[7,"/"],[2,[3,"url"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /wpp/:url(.:format)
 * @param {any} url
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const whatsapp_links_show_path = __jsr.r({"url":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"wpp"],[2,[7,"/"],[2,[3,"url"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /pa/marketing/whatsapp_links/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const whatsapp_links_update_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"marketing"],[2,[7,"/"],[2,[6,"whatsapp_links"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/marketing/whatsapp_links/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const whatsapp_links_update_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"marketing"],[2,[7,"/"],[2,[6,"whatsapp_links"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/whatsapp_listas/:customer_list_id(.:format)
 * @param {any} customer_list_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const whatsapp_lists_create_url = __jsr.r({"customer_list_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"whatsapp_listas"],[2,[7,"/"],[2,[3,"customer_list_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/whatsapp_listas/:customer_list_id(.:format)
 * @param {any} customer_list_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const whatsapp_lists_create_path = __jsr.r({"customer_list_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"whatsapp_listas"],[2,[7,"/"],[2,[3,"customer_list_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/whatsapp_listas(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const whatsapp_lists_index_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"whatsapp_listas"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/whatsapp_listas(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const whatsapp_lists_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"whatsapp_listas"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/whatsapp_listas/:id/search(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const whatsapp_lists_search_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"whatsapp_listas"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/whatsapp_listas/:id/search(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const whatsapp_lists_search_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"whatsapp_listas"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/whatsapp_listas/buscar_listas(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const whatsapp_lists_search_lists_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"whatsapp_listas"],[2,[7,"/"],[2,[6,"buscar_listas"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/whatsapp_listas/buscar_listas(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const whatsapp_lists_search_lists_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"whatsapp_listas"],[2,[7,"/"],[2,[6,"buscar_listas"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/whatsapp_listas/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const whatsapp_lists_show_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"whatsapp_listas"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/whatsapp_listas/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const whatsapp_lists_show_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"whatsapp_listas"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/whatsapp_listas/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const whatsapp_lists_update_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"whatsapp_listas"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/whatsapp_listas/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const whatsapp_lists_update_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"whatsapp_listas"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/whatsapp_listas/:customer_list_id/atualizar_interesse(.:format)
 * @param {any} customer_list_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const whatsapp_lists_update_customer_interested_url = __jsr.r({"customer_list_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"whatsapp_listas"],[2,[7,"/"],[2,[3,"customer_list_id"],[2,[7,"/"],[2,[6,"atualizar_interesse"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/whatsapp_listas/:customer_list_id/atualizar_interesse(.:format)
 * @param {any} customer_list_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const whatsapp_lists_update_customer_interested_path = __jsr.r({"customer_list_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"whatsapp_listas"],[2,[7,"/"],[2,[3,"customer_list_id"],[2,[7,"/"],[2,[6,"atualizar_interesse"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pa/crm/whatsapp_listas/:customer_list_id/atualizar_status(.:format)
 * @param {any} customer_list_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const whatsapp_lists_update_customer_status_url = __jsr.r({"customer_list_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"whatsapp_listas"],[2,[7,"/"],[2,[3,"customer_list_id"],[2,[7,"/"],[2,[6,"atualizar_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /pa/crm/whatsapp_listas/:customer_list_id/atualizar_status(.:format)
 * @param {any} customer_list_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const whatsapp_lists_update_customer_status_path = __jsr.r({"customer_list_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pa"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"whatsapp_listas"],[2,[7,"/"],[2,[3,"customer_list_id"],[2,[7,"/"],[2,[6,"atualizar_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

